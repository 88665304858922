import {Card} from "react-bootstrap";
import {Circle, Ellipse, Layer, Line, Rect, RegularPolygon, Stage, Star, Text} from "react-konva";
import React from "react";

const VenueLayout = ({elements, width, height}) => {
    return (
        <Card className={"mt-3"}>
            <Card.Header>
                <h5>Layout</h5>
            </Card.Header>
            <Card.Body className={"overflow-scroll"}>
                <Stage
                    width={width}
                    height={height}
                    className={"mt-3"}
                    style={{backgroundColor: "beige"}}
                >
                    <Layer>
                        {elements?.filter((element) => element.tool !== "pen" | element.tool !== "eraser")?.map(el => {
                            switch (el.type) {
                                case "rect":
                                    return (
                                        <Rect
                                            key={el.id}
                                            {...el}
                                        />
                                    );
                                case "circle":
                                    return (
                                        <Circle
                                            key={el.id}
                                            {...el}
                                        />
                                    );
                                case "ellipse":
                                    return (
                                        <Ellipse
                                            key={el.id}
                                            {...el}
                                        />
                                    );
                                case "line":
                                    return (
                                        <Line
                                            key={el.id}
                                            {...el}
                                        />
                                    );
                                case "star":
                                    return (
                                        <Star
                                            key={el.id}
                                            {...el}
                                        />
                                    );
                                case "polygon":
                                    return (
                                        <RegularPolygon
                                            key={el.id}
                                            {...el}
                                        />
                                    );
                                default:
                                    return null;
                            }
                        })}
                        {elements?.filter(el => el.tool === "pen" | el.tool === "eraser")?.map((line, i) => (
                            <Line
                                key={i}
                                points={line.points}
                                stroke="#df4b26"
                                strokeWidth={5}
                                tension={0.5}
                                lineCap="round"
                                lineJoin="round"
                                globalCompositeOperation={
                                    line.tool !== "" ? (line.tool === 'eraser' ? 'destination-out' : 'source-over') : ''
                                }
                            />
                        ))}
                        {elements
                            ?.filter(el => el.type === 'text')
                            ?.map(el => (
                                <Text
                                    key={el.id}
                                    x={el.x}
                                    y={el.y}
                                    text={el.label}
                                    fontSize={16}
                                />
                            ))}
                    </Layer>
                </Stage>
            </Card.Body>
        </Card>
    )
}

export default VenueLayout