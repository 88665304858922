import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Background from 'components/common/Background';
import corner6 from 'assets/img/illustrations/corner-6.png';
import {
    Card,
    Col,
    Form,
    Row,
} from 'react-bootstrap';
import IconButton from "../../components/common/IconButton";
import {useNavigate} from "react-router-dom";
import paths from "../../routes/paths";

const CollaborationHeader = ({query, fetch, t}) => {
    const navigate = useNavigate();
    const handleChange = (value) => {
        query.set("search", value);
        fetch(query)
        navigate(`?${query.toString()}`)
    }
    return (
        <Card className="mb-3">
            <Card.Header className="position-relative">
                <h5 className="mb-0 mt-1">All Collaborations</h5>
                <Background image={corner6} className="d-none d-md-block bg-card"/>
            </Card.Header>
            <Card.Body className="pt-0 pt-md-3">
                <Row className="g-3 align-items-center">
                    <Col>
                        <Form className="position-relative">
                            <Form.Control
                                type="search"
                                placeholder="Search..."
                                size="sm"
                                aria-label="Search"
                                className="rounded search-input ps-4"
                                onChange={({target}) => {
                                    handleChange(target.value);
                                }}
                            />
                            <FontAwesomeIcon
                                icon="search"
                                className="fs-10 text-400 position-absolute text-400 start-0 top-50 translate-middle-y ms-2"
                            />
                        </Form>
                    </Col>
                    <Col xs="auto" className="col-md">
                        <Row className="g-0 justify-content-end">
                            <Col
                                xs="auto"
                                className="position-md-static position-absolute top-0 end-0 mt-3 mt-md-0"
                            >
                                <IconButton
                                    variant="falcon-default"
                                    size="sm"
                                    icon="plus"
                                    transform="shrink-3"
                                    onClick={() => navigate(paths.collaborationCreate)}
                                >
                                    <span className="d-none d-sm-inline-block ms-1">{t('new', {ns: "common"})}</span>
                                </IconButton>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default CollaborationHeader;
