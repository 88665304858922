import CustomDateInput from 'components/common/CustomDateInput';
import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, OverlayTrigger, Row, Spinner, Tooltip} from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from "react-select";
import FormError from "../../errors/FormError";
import {capitalize} from "../../../helpers/utils";
import Flex from "../../../components/common/Flex";
import zoom from "assets/img/logos/zoom.png"
import gMeet from "assets/img/logos/google-meet.png"
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import TinymceEditor from "../../../components/common/TinymceEditor";

const EventDetails = ({values, setFieldValue, errors, t, setErrors}) => {
    const [loading, setLoading] = useState(false)
    const [selectedVenue, setSelectedVenue] = useState(values?.venue ? {
        value: values?.venue,
        label: capitalize(values?.venue)
    } : null)
    const [startDate, setStartDate] = useState(values?.start_date ? new Date(values?.start_date) : null)
    const [endDate, setEndDate] = useState(values?.end_date ? new Date(values?.end_date) : null)
    const [venues, setVenues] = useState([])
    const [selectedAddress, setSelectedAddress] = useState(values?.address ? {
        value: values?.address,
        label: values?.address_name
    } : null)
    const [zoomData, setZoomData] = useState(null)
    const [googleMeetData, setGoogleMeetData] = useState(null)

    const fetchVenues = async () => {
        await api.get("/venue/")
            .then(res => setVenues(res?.data?.results))
            .catch(() => {
            })
    }

    const handleChange = (name, value) => {
        setFieldValue(name, value);
    };

    const venueTypes = [
        {value: "", label: "--------------------------"},
        {value: "venue", label: t('fields.details.venue.venue')},
        {value: "online", label: t('fields.details.venue.online')},
    ]

    const handleZoomClick = async (e) => {
        e.preventDefault()
        setLoading(true)
        await api.post('/integrations/zoom/create_zoom_meeting/', {
            title: values?.title,
            start_date: values?.start_date,
            duration: "",
            description: values?.description,
        })
            .then(res => {
                toast.success("Zoom meeting created.", {theme: "colored"})
                setZoomData(res?.data)
                setFieldValue("zoom_integration", res?.data?.id)
            })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
                setErrors(err?.response?.data)
            })
        setLoading(false)
    };

    const handleGoogleClick = async (e) => {
        e.preventDefault()
        setLoading(true)
        await api.get("/schedule/generate_meeting_link/").then(async res => {
            await api.post("/integrations/google/", {
                meeting_id: res?.data?.meet_id,
                join_url: res?.data?.link,
                start_time: values?.start_date,
                integration_type: "Google Meet",
                duration: new Date(values?.end_date) - new Date(values?.start_date),
            })
                .then(response => {
                    toast.success("Google meeting created.", {theme: "colored"})
                    setGoogleMeetData(response?.data)
                    setFieldValue("google_meet_integration", response?.data?.id)
                })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setErrors(err?.response?.data)
                })
        })
            .catch(err => {
                toast.error("An error has occurred.", {theme: "colored"})
                setErrors(err?.response?.data)
            })
        setLoading(false)
    };

    useEffect(() => {
        if (values?.google_meet_integration) {
            setGoogleMeetData(values?.google_meet_integration_data)
        }
        if (values?.zoom_integration) {
            setZoomData(values?.zoom_integration_data)
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchVenues()
        // eslint-disable-next-line
    }, []);

    const venueOptions = [
        {label: "----------------------------", value: ""},
        ...venues?.map(v => ({label: v?.name, value: v?.id}))
    ]

    return (
        <Card className="mb-3">
            <Card.Header as="h5">{t('fields.details.title')}</Card.Header>
            <Card.Body className="bg-light">
                <Row className="gx-2 gy-3">
                    <Col md="6">
                        <Form.Group controlId="title">
                            <Form.Label>{t('fields.details.eventTitle')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="title"
                                placeholder={t('fields.details.eventTitle')}
                                value={values.title}
                                onChange={e => handleChange('title', e.target.value)}
                            />
                        </Form.Group>
                        <FormError error={errors.title}/>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="title">
                            <Form.Label>{t('fields.details.subtitle')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="subtitle"
                                placeholder={t('fields.details.subtitle')}
                                value={values.subtitle}
                                onChange={e => handleChange('subtitle', e.target.value)}
                            />
                        </Form.Group>
                        <FormError error={errors.subtitle}/>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="start_date">
                            <Form.Label>{t('fields.details.start_date')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Row>
                                <DatePicker
                                    selected={startDate}
                                    onChange={newDate => {
                                        setStartDate(newDate)
                                        handleChange('start_date', new Date(newDate).toISOString());
                                    }}
                                    showTimeSelect
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    fixedHeight
                                    className='form-control'
                                    customInput={
                                        <CustomDateInput
                                            formControlProps={{
                                                placeholder: 'MM/dd/yyyy h:mm aa',
                                            }}
                                        />
                                    }
                                />
                            </Row>
                        </Form.Group>
                        <FormError error={errors.start_date}/>
                    </Col>
                    <Col md="6">
                        <Form.Group controlId="end_date">
                            <Form.Label>{t('fields.details.end_date')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Row>
                                <DatePicker
                                    selected={endDate}
                                    onChange={newDate => {
                                        setEndDate(newDate)
                                        handleChange('end_date', new Date(newDate).toISOString());
                                    }}
                                    showTimeSelect
                                    dateFormat="MM/dd/yyyy h:mm aa"
                                    fixedHeight
                                    className='form-control'
                                    customInput={
                                        <CustomDateInput
                                            formControlProps={{
                                                placeholder: 'MM/dd/yyyy h:mm aa',
                                                name: 'end_date',
                                            }}
                                        />
                                    }
                                />
                            </Row>
                        </Form.Group>
                        <FormError error={errors.end_date}/>
                    </Col>
                    <Col md="12">
                        <Form.Group controlId="venue">
                            <Form.Label>{t('fields.details.venue.venue')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <Select
                                options={venueTypes}
                                type="text"
                                placeholder={t('fields.details.venue.venue')}
                                name="venue"
                                classNamePrefix={"react-select"}
                                value={selectedVenue}
                                onChange={(newValue) => {
                                    setSelectedVenue(newValue)
                                    handleChange("venue", newValue.value)
                                }}
                            />
                        </Form.Group>
                        <FormError error={errors.venue}/>
                    </Col>

                    <Col md="12">
                        <div className="border-dashed border-bottom"></div>
                    </Col>
                    {values?.venue === "online" && (
                        <Flex justifyContent={"between"} alignItems={"center"} wrap={"wrap"}>
                            {loading ? (
                                <Flex justifyContent="center" alignItems={'center'} className="p-2 mb-2">
                                    <Spinner animation={'border'} variant={'primary'}/>
                                </Flex>
                            ) : (
                                <>
                                    <OverlayTrigger
                                        key="left"
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip style={{position: "fixed"}} id="ThemeColor">
                                                Zoom
                                            </Tooltip>
                                        }
                                    >
                                        <Button variant="outline-primary" size={"sm"} onClick={handleZoomClick}>
                                            <img src={zoom} alt={"zoom logo"} width={"50"} height={"50"}/>
                                        </Button>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        key="left"
                                        placement={"bottom"}
                                        overlay={
                                            <Tooltip style={{position: "fixed"}} id="ThemeColor">
                                                Google Meet
                                            </Tooltip>
                                        }
                                    >
                                        <Button variant="outline-success" onClick={handleGoogleClick}>
                                            <img src={gMeet} alt={"google meet logo"} width={"50"} height={"50"}/>
                                        </Button>
                                    </OverlayTrigger>
                                    {/*<OverlayTrigger*/}
                                    {/*    key="left"*/}
                                    {/*    placement={"bottom"}*/}
                                    {/*    overlay={*/}
                                    {/*        <Tooltip style={{position: "fixed"}} id="ThemeColor">*/}
                                    {/*            Cisco Webex*/}
                                    {/*        </Tooltip>*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    <Button variant="outline-primary">*/}
                                    {/*        <img src={webex} alt={"cisco webex logo"} height={"50"}/>*/}
                                    {/*    </Button>*/}
                                    {/*</OverlayTrigger>*/}
                                    {/*<OverlayTrigger*/}
                                    {/*    key="left"*/}
                                    {/*    placement={"bottom"}*/}
                                    {/*    overlay={*/}
                                    {/*        <Tooltip style={{position: "fixed"}} id="ThemeColor">*/}
                                    {/*            Eventbrite*/}
                                    {/*        </Tooltip>*/}
                                    {/*    }*/}
                                    {/*>*/}
                                    {/*    <Button variant="outline-warning">*/}
                                    {/*        <img src={eventbrite} alt={"eventbrite logo"} height={"50"}/>*/}
                                    {/*    </Button>*/}
                                    {/*</OverlayTrigger>*/}
                                </>
                            )}
                        </Flex>
                    )}
                    {zoomData && (
                        <div>
                            <h5>{zoomData?.integration_type}</h5>
                            <p>Meeting ID: {zoomData?.meeting_id}</p>
                            <p>Join Link: <a href={zoomData?.join_url} target={"_blank"} rel={"noreferrer"}>Click here
                                to join</a></p>
                        </div>
                    )}
                    {googleMeetData && (
                        <div>
                            <h5>{googleMeetData?.integration_type}</h5>
                            <p>Meeting ID: {googleMeetData?.meeting_id}</p>
                            <p>Meeting Link: <a href={googleMeetData?.join_url} target={"_blank"} rel={"noreferrer"}>Click
                                here
                                to join</a>
                            </p>
                        </div>
                    )}
                    <FormError error={errors?.message}/>
                    {values?.venue === "venue" && (
                        <>
                            <Col md={12}>
                                <Select
                                    options={venueOptions}
                                    value={selectedAddress}
                                    onChange={(newValue) => {
                                        setSelectedAddress(newValue)
                                        setFieldValue("address", newValue.value)
                                    }}
                                    classNamePrefix={"react-select"}
                                />
                            </Col>
                        </>
                    )}
                    <Col md="12">
                        <Form.Group controlId="description">
                            <Form.Label>{t('fields.details.description')} <span
                                className={"text-danger"}>*</span></Form.Label>
                            <TinymceEditor
                                value={values?.description}
                                handleChange={newValue => setFieldValue("description", newValue)}
                            />
                        </Form.Group>
                        <FormError error={errors.description}/>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
export default EventDetails;
