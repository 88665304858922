import {Card} from "react-bootstrap";
import EventDetailsGmap from "../../events/DetailEvent/EventDetailsGmap";

const VenueMap = ({address}) => {
    return (
        <Card className={"mt-3"}>
            <Card.Body>
                <EventDetailsGmap address={address} location={address?.geometry?.location}/>
            </Card.Body>
        </Card>
    )
}

export default VenueMap