import {forwardRef} from "react";
import {Rect} from 'react-konva';

const Chair = forwardRef(({x, y, size, fill, ...props}, ref) => {
    return (
        <Rect
            x={x}
            y={y}
            width={size}
            height={size}
            fill={fill}
            ref={ref}
            {...props}
        />
    );
})

export default Chair;
