import {Card, Col, Row} from "react-bootstrap";
import Flex from "../../components/common/Flex";
import {Link} from "react-router-dom";
import paths from "../../routes/paths";
import React from "react";
import {formatDateTime} from "../../helpers/utils";

const CollaborationGrid = ({collaboration, t}) => {
    const {id, name, created_at, description, keywords_names, shared_with_names} = collaboration;

    return (
        <Card className="h-100 overflow-hidden">
            <Card.Body
                as={Flex}
                direction="column"
                justifyContent="between"
                className="p-0"
            >
                <div>
                    <div className="p-3">
                        <h5 className="fs-9 mb-2">
                            <Link to={paths.collaborationDetail.replace(':id', id)} className="text-1100">
                                {name}
                            </Link>
                        </h5>
                    </div>
                </div>
                <Row className="g-0 mb-3 align-items-end">
                    <Col className="ps-3">
                        <h4 className="fs--1 d-flex align-items-center">
                            <span dangerouslySetInnerHTML={{__html: description}}></span>
                        </h4>
                        <p className="mb-0 fs-10 text-800">
                            Shared with {shared_with_names.toLocaleString()}
                        </p>
                    </Col>
                    <Col xs="auto" className="pe-3">
                        <p>{formatDateTime(created_at)}</p>
                        <p>{keywords_names}</p>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default CollaborationGrid