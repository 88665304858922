import PricingDefault from "../../pricing-default/PricingDefault";
import Section from "../../../components/common/Section";

const Offers = () => {
    return (
        <Section fluid>
            <PricingDefault/>
        </Section>
    )
}

export default Offers