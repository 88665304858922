import {Card, Image} from "react-bootstrap";
import Slider from "react-slick";
import React from "react";

const VenuePhotos = ({images}) => {
    const sliderSettings = {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return images && (
        <Card className={"mt-3"}>
            <Card.Body>
                <div className="position-relative h-sm-auto overflow-hidden">
                    <Slider {...sliderSettings} className="slick-slider-arrow-inner">
                        {images?.map((img, index) => {
                            return (
                                <Image
                                    fluid
                                    rounded
                                    src={img?.image_url}
                                    alt={`Venue Photo ${index + 1}`}
                                    key={index}
                                />
                            )
                        })}
                    </Slider>
                </div>
            </Card.Body>
        </Card>
    )
}

export default VenuePhotos