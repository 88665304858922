import {Card, Col, Row} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import StarRating from "../../../components/common/StarRating";
import React from "react";
import Avatar from "../../../components/common/Avatar";

const VenueReviews = ({address}) => {
    return (
        <Card className={"mt-3"}>
            <Card.Header>
                <Flex justifyContent={"between"}>
                    <h5 className="card-title">Reviews</h5>
                    <StarRating rating={address?.rating} readonly/>
                </Flex>
            </Card.Header>
            <Card.Body>
                {address?.reviews?.length > 0 && (
                    address?.reviews?.map((review, index) => (
                        <Row key={index}>
                            <Col lg={2} className={"text-center"}>
                                <Avatar src={review?.profile_photo_url} size={"4xl"}/>
                            </Col>
                            <Col lg={10}>
                                <p>{review?.author_name} <span
                                    className={"fs--2 text-end"}>{review?.relative_time_description}</span></p>
                                <StarRating rating={review?.rating} readonly/>
                                <p className={"mt-2"}>{review?.text}</p>
                            </Col>
                        </Row>
                    ))
                )}
            </Card.Body>
        </Card>
    )
}

export default VenueReviews;