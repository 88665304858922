import {Rect} from 'react-konva';
import {forwardRef} from "react";


const Table = forwardRef(({x, y, width, height, fill = 'brown', ...props}, ref) => {
    return (
        <Rect
            x={x}
            y={y}
            width={width}
            height={height}
            fill={fill}
            ref={ref}
            {...props}
        />
    );
})

export default Table;
