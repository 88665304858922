import {Rect} from 'react-konva';
import {forwardRef} from "react";

const Door = forwardRef(({x, y, width = 10, height = 40, fill = 'darkgreen', ...props}, ref) => {
    return (
        <Rect
            x={x}
            y={y}
            width={width}
            height={height}
            fill={fill}
            ref={ref}
            {...props}
        />
    );
})

export default Door;
