import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import paths from "../../../routes/paths";
import {Col, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import VenueHeader from "./VenueHeader";
import VenuePhotos from "./VenuePhotos";
import VenueReviews from "./VenueReviews";
import VenueMap from "./VenueMap";
import VenueContactDetail from "./VenueContactDetail";
import VenueLayout from "./VenueLayout";

const VenueDetail = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [venue, setVenue] = useState({});

    const {id} = useParams();
    const navigate = useNavigate();

    const fetchVenueDetails = async () => {
        setLoading(true);
        await api.get(`/venue/${id}/`)
            .then(res => setVenue(res?.data))
            .catch(err => {
                if (err.response.status === 404) navigate(paths.error404)
            })
        setLoading(false)
    }

    useEffect(() => {
        fetchVenueDetails()
        // eslint-disable-next-line
    }, [])

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Fragment>
            <VenueHeader venue={venue} t={t} setLoading={setLoading}/>
            <Row>
                <Col>
                    <VenuePhotos images={venue?.images_data}/>
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <VenueMap address={venue?.address}/>
                </Col>
                <Col lg={6}>
                    <div className={"sticky-sidebar"}>
                        <VenueContactDetail address={venue?.address}/>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <VenueLayout elements={venue?.elements_data} width={venue?.width} height={venue?.height}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <VenueReviews address={venue?.address}/>
                </Col>
            </Row>
        </Fragment>
    )
}

export default withPermission(withTranslation(["venue", "common"])(VenueDetail), "venue.view_venue")