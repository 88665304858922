import paths, {rootPaths} from "./paths";
import {
    faAddressBook,
    faBalanceScale,
    faBook, faBug,
    faBuilding,
    faBusinessTime,
    faChartBar,
    faChartLine, faClock,
    faCodeBranch,
    faCoins,
    faCommentDots,
    faDirections, faDollarSign, faFileContract, faGraduationCap, faHandshake,
    faHouseUser, faMailBulk, faMapMarked,
    faMoneyBill, faMoneyCheckAlt, faPaperPlane,
    faReply,
    faStore, faTicketAlt,
    faUserCircle,
    faUserGraduate,
    faUserPlus,
    faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import {getItemFromStore} from "../helpers/utils";

const isAuthenticated = getItemFromStore("isAuthenticated", false)
const superUser = getItemFromStore("isSuperUser", false)
const staffUser = getItemFromStore("isStaff", false)
const activityUser = getItemFromStore("isActivity", false)
const companyUser = getItemFromStore("isCompany", false)

export const dashboardRoutes = {
    label: "dashboard.title",
    labelDisable: true,
    children: [
        {
            name: "dashboard.title",
            active: true,
            icon: "chart-pie",
            permissions: ["dashboard.view_dashboard", "analytics.view_analytics"],
            accessible: superUser || staffUser || activityUser || companyUser || isAuthenticated,
            children: [
                {
                    name: "dashboard.default",
                    to: rootPaths.dashboardRoot,
                    exact: true,
                    icon: faChartLine,
                    active: true,
                    accessible: superUser || staffUser || activityUser || companyUser || isAuthenticated,
                    permission: "dashboard.view_dashboard"
                },
                {
                    name: "dashboard.analytics",
                    to: paths.analytics,
                    icon: faChartBar,
                    active: true,
                    permission: "analytics.view_analytics",
                    accessible: superUser || activityUser,
                },
                {
                    name: "dashboard.finance",
                    to: paths.dashboardFinance,
                    icon: faCoins,
                    active: true,
                    permission: "finance.view_finance",
                    accessible: superUser || activityUser,
                },
            ],
        },
    ],
};
export const appRoutes = {
    label: "app",
    children: [
        {
            name: "accounts.title",
            icon: faUserTie,
            active: true,
            permissions: ["accounts.view_user", "auth.view_group"],
            accessible: superUser || staffUser || activityUser || companyUser,
            children: [
                {
                    name: "accounts.users",
                    icon: "user",
                    to: paths.users,
                    active: true,
                    permission: "accounts.view_user",
                    accessible: superUser || staffUser || activityUser || companyUser
                },
                {
                    name: "accounts.groups",
                    icon: "users",
                    to: paths.groups,
                    active: true,
                    permission: "auth.view_group",
                    accessible: superUser || staffUser
                },
            ],
        },
        {
            name: "members.title",
            icon: faUserCircle,
            active: true,
            permissions: ["member.view_member", "member.view_role"],
            accessible: superUser || staffUser || activityUser,
            children: [
                {
                    name: "members.members",
                    icon: faHouseUser,
                    to: paths.members,
                    active: true,
                    permission: "member.view_member",
                    accessible: superUser || staffUser || activityUser
                },
                {
                    name: "members.roles",
                    icon: faDirections,
                    to: paths.roles,
                    active: true,
                    permission: "member.view_role",
                    accessible: superUser || staffUser || activityUser
                },
            ],
        },
        {
            name: "addresses",
            icon: faAddressBook,
            to: `/${rootPaths.addressRoot}`,
            active: true,
            permission: "address.view_address",
            accessible: superUser || staffUser || activityUser
        },
        {
            name: "providers",
            icon: faMoneyCheckAlt,
            to: `/${rootPaths.providerRoot}`,
            active: true,
            permission: "transaction.view_transactionprovider",
            accessible: superUser || staffUser || activityUser
        },
        {
            name: "emails",
            icon: faMailBulk,
            to: `/${rootPaths.enoticeRoot}`,
            active: true,
            permission: "enotice.view_enotice",
            accessible: superUser || staffUser || activityUser
        },
        {
            name: "events",
            icon: "calendar-day",
            to: paths.events,
            active: true,
            permission: "event.view_event",
            accessible: superUser || staffUser || activityUser || companyUser || isAuthenticated
        },
        {
            name: "venues",
            icon: faMapMarked,
            to: paths.venueList,
            active: true,
            permission: "venue.view_venue",
            accessible: superUser || staffUser || activityUser || companyUser || isAuthenticated
        },
        // {
        // 	name: "social",
        // 	icon: "share-alt",
        // 	to: "#!",
        // 	active: true,
        // 	groups: [adminGroupName],
        // 	permission: "feed.view_post",
        // 	badge: {
        // 		type: "warning",
        // 		text: "Coming soon"
        // 	}
        // },
        {
            name: "collaboration",
            icon: faHandshake,
            to: paths.collaborationCenter,
            active: true,
            permission: "collaboration.view_collaboration",
            accessible: superUser || staffUser || activityUser
        },
        {
            name: "management.billings",
            icon: faMoneyBill,
            to: paths.billingList,
            active: true,
            permission: "billing.view_billing",
            accessible: superUser || staffUser || activityUser
        },
        {
            name: "management.title",
            icon: "wrench",
            active: true,
            permissions: ["organizer.view_organizer", "sponsor.view_sponsor", "join.view_join", "membership.view_membership", "blog.view_blogpost", "activity.view_activityperiodictask"],
            accessible: superUser || staffUser || activityUser || companyUser,
            children: [
                {
                    name: "management.blog",
                    icon: faBook,
                    active: true,
                    to: paths.blog,
                    permission: "blog.view_blogpost",
                    accessible: superUser || staffUser
                },
                {
                    name: "management.testimonial",
                    icon: faCommentDots,
                    active: true,
                    to: paths.testimonial,
                    permission: "testimonial.view_testimonial",
                    accessible: superUser || staffUser
                },
                {
                    name: "management.currencies",
                    icon: faDollarSign,
                    active: true,
                    to: paths.currencyList,
                    permission: "currency.view_currency",
                    accessible: superUser || staffUser
                },
                {
                    name: "management.memberships",
                    icon: faUserPlus,
                    active: true,
                    to: paths.membershipList,
                    permission: "membership.view_membership",
                    accessible: superUser || staffUser
                },
                {
                    name: "management.newsletter",
                    icon: faPaperPlane,
                    active: true,
                    to: paths.newsletter,
                    permission: "newsletter.view_newsletter",
                    accessible: superUser || staffUser
                },
                {
                    name: "management.organizers",
                    icon: faUserGraduate,
                    to: paths.organizers,
                    active: true,
                    permission: "organizer.view_organizer",
                    accessible: superUser || staffUser || activityUser
                },
                {
                    name: "management.sponsors",
                    icon: faStore,
                    to: paths.sponsors,
                    active: true,
                    permission: "sponsor.view_sponsor",
                    accessible: superUser || staffUser || activityUser || companyUser
                },
                {
                    name: "management.companies",
                    icon: faBusinessTime,
                    to: paths.companies,
                    active: true,
                    permission: "company.view_company",
                    accessible: superUser || staffUser || activityUser || companyUser
                },
                {
                    name: "management.requests",
                    icon: faReply,
                    to: paths.request,
                    active: true,
                    permission: "join.view_join",
                    accessible: superUser || staffUser || activityUser
                },
                {
                    name: "management.rules",
                    icon: faBalanceScale,
                    to: paths.rules,
                    active: true,
                    permission: "rule.view_rule",
                    accessible: superUser || staffUser || activityUser
                },
                {
                    name: "management.contracts",
                    icon: faFileContract,
                    to: paths.contracts,
                    active: true,
                    permission: "contract.view_contract",
                    accessible: superUser || staffUser || activityUser
                },
                {
                    name: "management.periodictasks",
                    icon: faClock,
                    to: paths.periodic,
                    active: true,
                    permission: "activity.view_activityperiodictask",
                    accessible: superUser || staffUser || activityUser
                },
            ],
        },
        {
            name: "Support Desk",
            icon: faTicketAlt,
            active: true,
            permissions: ["support.view_supportticket"],
            accessible: superUser || staffUser || activityUser,
            children: [
                {
                    name: "Tickets",
                    to: paths.supportTable,
                    active: true,
                    permission: "support.view_supportticket",
                    accessible: superUser || staffUser || activityUser
                },
            ]
        },
        {
            name: "activities",
            icon: faBuilding,
            to: paths.activityList,
            active: true,
            permission: "activity.view_activity",
            accessible: superUser || staffUser || activityUser || companyUser || isAuthenticated,
        },
        {
            name: "timeline",
            icon: faCodeBranch,
            to: paths.timeline,
            active: true,
            permission: "release_note.view_release",
            accessible: superUser || staffUser || activityUser || companyUser || isAuthenticated
        },
        {
            name: "tutorials",
            icon: faGraduationCap,
            to: paths.tutorial,
            active: true,
            permission: "tutorials.view_tutorial",
            accessible: superUser || staffUser || activityUser || companyUser || isAuthenticated
        },
        {
            name: "bug",
            icon: faBug,
            to: (superUser || staffUser) ? paths.bug : paths.bugCreate,
            active: true,
            permission: "bug.add_bug",
            accessible: superUser || staffUser || activityUser || companyUser || isAuthenticated
        },
    ],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [dashboardRoutes, appRoutes];
