import {Card} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAt, faGlobe, faPhone} from "@fortawesome/free-solid-svg-icons";

const VenueContactDetail = ({address}) => {
    return (
        <Card className={"mt-3"}>
            <Card.Header>
                <h5>Contact Details</h5>
            </Card.Header>
            <Card.Body>
                <p><FontAwesomeIcon icon={faAt}/> {address?.formatted_address}</p>
                <p><FontAwesomeIcon icon={faPhone}/> <a
                    href={`tel:${address?.international_phone_number}`}>{address?.international_phone_number}</a></p>
                <p><FontAwesomeIcon icon={faGlobe}/> <a href={address?.website} target={"_blank"}
                                                        rel={"noreferrer"}>{address?.website}</a>
                </p>
                <p>
                    Opening Hours:
                    <ul>
                        {address?.opening_hours?.weekday_text?.map(text => (
                            <li>{text}</li>
                        ))}
                    </ul>
                </p>
            </Card.Body>
        </Card>
    )
}

export default VenueContactDetail