import React, {useEffect, useState} from "react";
import is from "is_js";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import {Chart as ChartJS, registerables} from "chart.js";
import {CloseButton} from "components/common/Toast";
import {useAppContext} from "providers/AppProvider";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import {PopupWidget} from "react-calendly";
import {api} from "./utils/api";
import {useTranslation} from "react-i18next";

ChartJS.register(...registerables);

// eslint-disable-next-line react/prop-types
const App = () => {
    const [socialAccounts, setSocialAccounts] = useState([])
    const HTMLClassList = document.getElementsByTagName("html")[0].classList;
    const {
        config: {navbarPosition}
    } = useAppContext();

    const {t} = useTranslation("footer")

    const getSocialAccounts = async () => {
        await api.get("/account/current_user/").then(res => setSocialAccounts(res?.data?.social_accounts))
            .catch(error => {

            })
    }
    useEffect(() => {
        getSocialAccounts()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add("windows");
        }
        if (is.chrome()) {
            HTMLClassList.add("chrome");
        }
        if (is.firefox()) {
            HTMLClassList.add("firefox");
        }
        if (is.safari()) {
            HTMLClassList.add("safari");
        }
    }, [HTMLClassList]);

    useEffect(() => {
        if (navbarPosition === "double-top") {
            HTMLClassList.add("double-top-nav-layout");
        }
        return () => HTMLClassList.remove("double-top-nav-layout");
        //eslint-disable-next-line
    }, [navbarPosition]);

    let social = socialAccounts?.filter(social => social?.provider === "calendly")[0]

    return (
        <>
            <Outlet/>
            <ToastContainer
                closeButton={CloseButton}
                icon={false}
                position="bottom-left"
                className={"d-print-none"}
            />
            {social?.extra_data?.scheduling_url &&
                <div className={"d-print-none"}>
                    <PopupWidget
                        url={social?.extra_data?.scheduling_url}
                        rootElement={document.getElementById("main")}
                        text={t('schedule')}
                        textColor="#ffffff"
                        color="#00a2ff"
                    />
                </div>
            }
        </>
    );
};

export default App;
