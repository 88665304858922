import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons";
import paths from "../../routes/paths";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render shows the fallback UI.
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an external service here
        console.error("ErrorBoundary caught an error", error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{textAlign: "center", marginTop: "50px"}}>
                    <FontAwesomeIcon icon={faExclamationCircle} className={"text-danger"} size={"10x"}/>
                    <h1>Something went wrong.</h1>
                    <p>There was a problem processing the request. Please try again</p>
                    <p>To report an issue, <a href={paths.bugCreate}>click here</a></p>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;