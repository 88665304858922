import {withPermission} from "../../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {useEffect, useRef, useState} from "react";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {Field, Formik} from "formik";
import CSRFToken from "../../../helpers/CSRFToken";
import {Stage, Layer, Rect, Circle, Ellipse, Line, Text, Transformer, Star, RegularPolygon} from 'react-konva';
import {v4 as uuid} from "uuid"
import {
    faChartLine,
    faCircle,
    faCircleNotch,
    faDrawPolygon, faSave,
    faShapes,
    faStar, faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "../../../components/common/IconButton";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import FormError from "../../errors/FormError";
import paths from "../../../routes/paths";
import {Autocomplete} from "@react-google-maps/api";
import GoogleMap from "../../../components/map/GoogleMap";
import VenueUpload from "./VenueUpload";
import RoomSection from "./RoomSection";

const VenueAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [elements, setElements] = useState([]);
    const [selectedId, setSelectedId] = useState(null);
    const [tool, setTool] = useState('pen');
    const [lines, setLines] = useState([]);
    const [errors, setErrors] = useState({})
    const [map, setMap] = useState(null);
    const [autoComplete, setAutoComplete] = useState(null)
    const [position, setPosition] = useState({
        lat: -33.8688,
        lng: 151.2195
    })
    const [formData, setFormData] = useState({
        name: "",
        description: "",
        width: 0,
        height: 0,
        capacity: 0,
        facilities: JSON.stringify(
            {
                WiFi: true,
                Parking: true,
                Accessibility: {
                    Wheelchair: true,
                    HearingAssistance: false,
                },
                Other: "Swimming Pool, Gym",
            },
            null,
            2 // Pretty-print JSON with 2-space indentation
        ),
        elements: [],
        rooms: [
            {
                name: '',
                width: 0,
                height: 0,
                capacity: 0,
                elements: [],
            },
        ],
        images: []
    });

    const shapeRef = useRef();
    const trRef = useRef();
    const isDrawing = useRef(false);
    const {id} = useParams()
    const navigate = useNavigate()

    const fetchVenue = async () => {
        setLoading(true)
        await api.get(`/venue/${id}/`)
            .then(res => {
                const updatedElements = res?.data?.elements_data?.map(el => ({
                    type: el?.type,
                    tool: el?.tool,
                    x: el?.x,
                    y: el?.y,
                    width: el?.width,
                    height: el?.height,
                    radius: el?.radius,
                    sides: el?.sides,
                    radiusX: el?.radiusX,
                    radiusY: el?.radiusY,
                    strokeWidth: el?.strokeWidth,
                    stroke: el?.stroke,
                    points: el?.points,
                    innerRadius: el?.innerRadius,
                    outerRadius: el?.outerRadius,
                    numPoints: el?.numPoints,
                    fill: el?.fill,
                    label: el?.label
                }))
                const updatedImages = res?.data?.images_data?.map(image => ({
                    data: {
                        ...image?.data,
                        preview: image?.image_url
                    },
                    image: {
                        ...image?.data,
                        preview: image?.image_url
                    },
                }))
                const updatedRooms = res?.data?.rooms_data
                setFormData({
                    ...res?.data,
                    elements: updatedElements,
                    rooms: updatedRooms,
                    images: updatedImages
                })
                setLines(res?.data?.elements_data?.filter((element) => element.tool === "pen" | element.tool === "eraser"))
                setElements(res?.data?.elements_data?.filter((element) => element.tool !== "pen" | element.tool !== "eraser"))
                setPosition(res?.data?.address?.geometry?.location)
            })
        setLoading(false)
    }

    const handleDragEnd = (e, id, setFieldValue) => {
        const updatedElements = elements.map(el =>
            el.id === id
                ? {...el, x: e.target.x(), y: e.target.y()}
                : el
        );
        setElements(updatedElements);
        setFieldValue("elements", updatedElements)
    };

    const handleAddElement = (type, setFieldValue) => {
        const newElement = {
            id: uuid(),
            type,
            x: 50,
            y: 50,
            fill: "blue",
            ...getDefaultDimensions(type),
        };
        setElements([...elements, newElement]);
        setFieldValue("elements", [...elements, newElement])
    };

    const handleMouseDown = (e, setFieldValue) => {
        checkDeselect(e)
        if (!selectedId) {
            isDrawing.current = true;
            const pos = e.target.getStage().getPointerPosition();
            setLines([...lines, {tool, points: [pos.x, pos.y]}]);
            setFieldValue("elements", [...elements, ...lines, {tool, points: [pos.x, pos.y]}])
        }
    };

    const handleMouseMove = (e, setFieldValue) => {
        // no drawing - skipping
        if (!isDrawing.current) {
            return;
        }
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();
        let lastLine = lines[lines.length - 1];
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        // replace last
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
        setFieldValue("elements", [...elements, ...lines.concat()])
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
    };

    const handleDeleteElement = (setFieldValue) => {
        if (selectedId) {
            setElements(elements.filter(el => el.id !== selectedId));
            setSelectedId(null);
            setFieldValue("elements", elements.filter(el => el.id !== selectedId))
        }
    };

    const getDefaultDimensions = (type) => {
        switch (type) {
            case "circle":
                return {radius: 30};
            case "ellipse":
                return {radiusX: 50, radiusY: 30};
            case "line":
                return {points: [20, 20, 100, 100], stroke: "black", strokeWidth: 2};
            case "star":
                return {numPoints: 5, innerRadius: 20, outerRadius: 40};
            case "polygon":
                return {sides: 6, radius: 40};
            default:
                return {width: 50, height: 50};
        }
    };

    const checkDeselect = (e) => {
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelectedId(null);
        }
    };

    const onLoad = (map) => {
        setMap(map);
    };

    const onPlaceChanged = (setFieldValue) => {
        if (map && autoComplete) {
            let markers = [];
            const place = autoComplete.getPlace();
            if (!place.geometry || !place.geometry.location) {
                console.error('Invalid place');
                return;
            }

            const icon = {
                url: place.icon,
                size: new window.google.maps.Size(71, 71),
                origin: new window.google.maps.Point(0, 0),
                anchor: new window.google.maps.Point(17, 34),
                scaledSize: new window.google.maps.Size(25, 25),
            };

            setPosition({
                lat: place.geometry.location.lat(),
                lng: place.geometry.location.lng()
            });

            markers.push(
                new window.google.maps.Marker({
                    map,
                    icon,
                    title: place.name,
                    position: place.geometry.location,
                }),
            );

            const bounds = new window.google.maps.LatLngBounds();
            if (place.geometry.viewport) {
                // Only geocodes have viewport.
                bounds.union(place.geometry.viewport);
            } else {
                bounds.extend(place.geometry.location);
            }
            map.fitBounds(bounds);

            setFieldValue("address", place)
        }
    };

    useEffect(() => {
        if (id) fetchVenue()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (selectedId) {
            // we need to attach transformer manually
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedId]);

    const handleSubmit = async (e, values) => {
        e.preventDefault()
        console.log(values)
        setLoading(true)
        if (!id) {
            await api.post("/venue/", values).then((res) => {
                toast.success("Venue successfully added.", {theme: "colored"});
                navigate(paths.venueDetail.replace(":id", res?.data?.id));
            })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setFormData(values)
                    setErrors(err?.response?.data)
                })
        } else {
            await api.patch(`/venue/${id}/`, values).then((res) => {
                toast.success("Venue successfully updated.", {theme: "colored"});
                navigate(paths.venueDetail.replace(":id", res?.data?.id));
            })
                .catch(err => {
                    toast.error("An error has occurred.", {theme: "colored"})
                    setFormData(values)
                    setErrors(err?.response?.data)
                })
        }
        setLoading(false)
    }

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Formik initialValues={formData} onSubmit={values => console.log(values)}>
            {({values, setFieldValue}) => (
                <Form>
                    <CSRFToken/>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header>
                                    <h5>{t('fields.basic')}</h5>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.name')} <span className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"name"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"name"}
                                                            type="text"
                                                            placeholder={t("fields.name")}
                                                            value={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.name}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.width')} <span className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"width"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"width"}
                                                            type="number"
                                                            placeholder={t("fields.width")}
                                                            value={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.width}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.height')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"height"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"height"}
                                                            type="number"
                                                            placeholder={t("fields.height")}
                                                            value={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.height}/>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.capacity')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={"capacity"}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            name={"capacity"}
                                                            type="number"
                                                            placeholder={t("fields.capacity")}
                                                            value={field.value}
                                                            onChange={e => setFieldValue(field.name, e.target.value)}
                                                        />
                                                    )}
                                                </Field>
                                                <FormError error={errors?.capacity}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.address')} </Form.Label>
                                                <Field name={"address"}>
                                                    {({field}) => (
                                                        <GoogleMap
                                                            initialCenter={position}
                                                            onLoad={onLoad}
                                                            mapContainerStyle={{
                                                                width: '100%',
                                                                height: '40vh'
                                                            }}
                                                            extras={
                                                                <Autocomplete
                                                                    onLoad={autocomplete => {
                                                                        setAutoComplete(autocomplete)
                                                                    }}
                                                                    onPlaceChanged={() => onPlaceChanged(setFieldValue)}
                                                                >
                                                                    <input
                                                                        type="text"
                                                                        placeholder={`${t('fields.address')} *`}
                                                                        style={{
                                                                            boxSizing: `border-box`,
                                                                            border: `1px solid transparent`,
                                                                            width: `240px`,
                                                                            height: `32px`,
                                                                            padding: `0 12px`,
                                                                            borderRadius: `3px`,
                                                                            boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                                                                            fontSize: `14px`,
                                                                            outline: `none`,
                                                                            textOverflow: `ellipses`,
                                                                            position: "absolute",
                                                                            left: "50%",
                                                                            marginLeft: "-120px"
                                                                        }}
                                                                    />
                                                                </Autocomplete>
                                                            }
                                                        >
                                                        </GoogleMap>
                                                    )}
                                                </Field>
                                                <FormError error={errors?.address}/>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Row>
                                <Col lg={6}>
                                    <div className={"sticky-sidebar"}>
                                        <VenueUpload setFieldValue={setFieldValue} values={values} t={t}/>
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className={"sticky-sidebar"}>
                                        <Card className={"mt-3"}>
                                            <Card.Header>
                                                <h5>{t('fields.other')}</h5>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Group>
                                                    <Form.Label>{t('fields.phone_number')}</Form.Label>
                                                    <Field name={"phone_number"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                type={"number"}
                                                                value={field.value}
                                                                onChange={e => setFieldValue(field.name, e.target.value)}
                                                                placeholder={t('fields.phone_number')}
                                                            />
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>{t('fields.website')}</Form.Label>
                                                    <Field name={"website"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                type={"url"}
                                                                value={field.value}
                                                                onChange={e => setFieldValue(field.name, e.target.value)}
                                                                placeholder={t('fields.website')}
                                                            />
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>{t('fields.facilities')}</Form.Label>
                                                    <Field name={"facilities"}>
                                                        {({field}) => (
                                                            <textarea value={field.value}
                                                                      {...field}
                                                                      rows={5}
                                                                      onChange={e => setFieldValue(field.name, e.target.value)}
                                                                      className={"form-control"}>
                                                        </textarea>
                                                        )}
                                                    </Field>
                                                </Form.Group>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </Col>
                            </Row>
                            <Card className={"mt-3"}>
                                <Card.Header>
                                    <h5>{t('fields.elements')}</h5>
                                </Card.Header>
                                <Card.Body className={"overflow-scroll"}>
                                    <Row>
                                        <Col>
                                            <Form.Select
                                                value={tool}
                                                onChange={(e) => {
                                                    setTool(e.target.value);
                                                }}
                                                size={"sm"}
                                                className={"d-inline-block me-3"}
                                                style={{width: "12rem"}}
                                            >
                                                <option value="pen">Pen</option>
                                                <option value="eraser">Eraser</option>
                                            </Form.Select>
                                            <IconButton
                                                icon={faShapes}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("rect", setFieldValue)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Rectangle</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faCircle}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("circle", setFieldValue)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Circle</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faCircleNotch}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("ellipse", setFieldValue)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Ellipse</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faChartLine}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("line", setFieldValue)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Line</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faStar}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("star", setFieldValue)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Star</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faDrawPolygon}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("polygon", setFieldValue)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Polygon</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faTrashAlt}
                                                variant="danger"
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleDeleteElement(setFieldValue)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Delete Selected</span>
                                            </IconButton>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Stage
                                                width={values.width}
                                                height={values.height}
                                                onTouchStart={checkDeselect}
                                                onMouseDown={e => handleMouseDown(e, setFieldValue)}
                                                onMousemove={e => handleMouseMove(e, setFieldValue)}
                                                onMouseup={handleMouseUp}
                                                className={"mt-3"}
                                                style={{backgroundColor: "beige"}}
                                            >
                                                <Layer>
                                                    {elements.map(el => {
                                                        switch (el.type) {
                                                            case "rect":
                                                                return (
                                                                    <Rect
                                                                        key={el.id}
                                                                        ref={shapeRef}
                                                                        {...el}
                                                                        draggable
                                                                        onClick={() => setSelectedId(el.id)}
                                                                        onDragEnd={(e) => handleDragEnd(e, el.id, setFieldValue)}
                                                                    />
                                                                );
                                                            case "circle":
                                                                return (
                                                                    <Circle
                                                                        key={el.id}
                                                                        ref={shapeRef}
                                                                        {...el}
                                                                        draggable
                                                                        onClick={() => setSelectedId(el.id)}
                                                                        onDragEnd={(e) => handleDragEnd(e, el.id, setFieldValue)}
                                                                    />
                                                                );
                                                            case "ellipse":
                                                                return (
                                                                    <Ellipse
                                                                        key={el.id}
                                                                        ref={shapeRef}
                                                                        {...el}
                                                                        draggable
                                                                        onClick={() => setSelectedId(el.id)}
                                                                        onDragEnd={(e) => handleDragEnd(e, el.id, setFieldValue)}
                                                                    />
                                                                );
                                                            case "line":
                                                                return (
                                                                    <Line
                                                                        key={el.id}
                                                                        ref={shapeRef}
                                                                        {...el}
                                                                        draggable
                                                                        onClick={() => setSelectedId(el.id)}
                                                                        onDragEnd={(e) => handleDragEnd(e, el.id, setFieldValue)}
                                                                    />
                                                                );
                                                            case "star":
                                                                return (
                                                                    <Star
                                                                        key={el.id}
                                                                        ref={shapeRef}
                                                                        {...el}
                                                                        draggable
                                                                        onClick={() => setSelectedId(el.id)}
                                                                        onDragEnd={(e) => handleDragEnd(e, el.id, setFieldValue)}
                                                                    />
                                                                );
                                                            case "polygon":
                                                                return (
                                                                    <RegularPolygon
                                                                        key={el.id}
                                                                        ref={shapeRef}
                                                                        {...el}
                                                                        draggable
                                                                        onClick={() => setSelectedId(el.id)}
                                                                        onDragEnd={(e) => handleDragEnd(e, el.id, setFieldValue)}
                                                                    />
                                                                );
                                                            default:
                                                                return null;
                                                        }
                                                    })}
                                                    {lines.map((line, i) => (
                                                        <Line
                                                            key={i}
                                                            points={line.points}
                                                            stroke="#df4b26"
                                                            strokeWidth={5}
                                                            tension={0.5}
                                                            lineCap="round"
                                                            lineJoin="round"
                                                            globalCompositeOperation={
                                                                tool !== "" ? (line.tool === 'eraser' ? 'destination-out' : 'source-over') : ''
                                                            }
                                                            draggable
                                                            onClick={() => setSelectedId(line.id)}
                                                            onDragEnd={(e) => handleDragEnd(e, line.id, setFieldValue)}
                                                        />
                                                    ))}
                                                    {selectedId && <Transformer ref={trRef}/>}
                                                    {elements
                                                        .filter(el => el.type === 'text')
                                                        .map(el => (
                                                            <Text
                                                                key={el.id}
                                                                x={el.x}
                                                                y={el.y}
                                                                text={el.label}
                                                                fontSize={16}
                                                                draggable
                                                                onDragEnd={(e) => handleDragEnd(e, el.id)}
                                                            />
                                                        ))}
                                                </Layer>
                                            </Stage>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            <Card className={"mt-3"}>
                                <Card.Header>
                                    <h5>{t('fields.rooms')}</h5>
                                </Card.Header>
                                <Card.Body>
                                    <RoomSection values={values} setFieldValue={setFieldValue} errors={errors} t={t}/>
                                </Card.Body>
                            </Card>
                            <Card className={"mt-3"}>
                                <Card.Footer>
                                    <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                        <p className={"text-danger"}>* {t('mandatory', {ns: "common"})}</p>
                                        <IconButton
                                            icon={faSave}
                                            onClick={e => handleSubmit(e, values)}
                                        >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t('save', {ns: "common"})}
                        </span>
                                        </IconButton>
                                    </Flex>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            )}
        </Formik>
    )
}
export default withPermission(withTranslation(["venue", "common"])(VenueAddEdit), ["venue.add_venue", "venue.change_venue"])