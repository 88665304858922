import {Text} from 'react-konva';
import {forwardRef} from "react";


const TextShape = forwardRef(({x, y, text, fontSize = 16, fill = 'black', ...props}, ref) => {
    return (
        <Text
            x={x}
            y={y}
            text={text}
            fontSize={fontSize}
            fill={fill}
            ref={ref}
            {...props}
        />
    );
})

export default TextShape;
