import IconButton from "../../../components/common/IconButton";
import Flex from "../../../components/common/Flex";
import {Card} from "react-bootstrap";
import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {usePermissions} from "../../../providers/PermissionsProvider";
import paths from "../../../routes/paths";
import Swal from "sweetalert2";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";

const VenueHeader = ({venue, t, setLoading}) => {
    const navigate = useNavigate();
    const {hasPermission} = usePermissions();

    const {id} = useParams();

    const handleDelete = async e => {
        const {isConfirmed} = await Swal.fire({
            title: t('sure', {ns: "common"}),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: `${t('yes', {ns: "common"})}, ${t('deleteConfirm', {ns: "common"})}`,
            cancelButtonText: t('cancel', {ns: "common"})
        });
        if (isConfirmed) {
            e.preventDefault();
            setLoading(true);
            await api.delete(`/venue/${id}/`).then(() => {
                toast.success(
                    `${t('title')} ${venue?.name} ${t('deleteSuccess', {ns: "common"})}`,
                    {
                        theme: 'colored'
                    }
                );
                navigate(paths.venueList)
            });
            setLoading(false);
        }
    };

    return (
        <Card>
            <Card.Header className="d-flex flex-between-center">
                <Flex alignItems={"center"}>
                    <IconButton
                        onClick={() => navigate(-1)}
                        variant="falcon-default"
                        size="sm"
                        icon="arrow-left"
                    />
                    <h5 className={"ms-3"}>{venue?.name}</h5>
                </Flex>
                <Flex>
                    {hasPermission('venue.change_venue') &&
                        <IconButton
                            variant="falcon-default"
                            size="sm"
                            icon="edit"
                            transform="shrink-2"
                            iconAlign="middle"
                            className="ms-2 d-none d-sm-block"
                            onClick={(e) => navigate(paths.venueEdit.replace(":id", venue.id))}
                        >
                            <span className="d-none d-md-inline-block ms-1">Edit</span>
                        </IconButton>
                    }
                    {hasPermission('venue.delete_venue') &&
                        <IconButton
                            variant="falcon-danger"
                            size="sm"
                            icon="trash-alt"
                            transform="shrink-2"
                            iconAlign="middle"
                            className="ms-2 d-none d-sm-block"
                            onClick={handleDelete}
                        >
                            <span className="d-none d-md-inline-block ms-1">Delete</span>
                        </IconButton>
                    }
                </Flex>
            </Card.Header>
        </Card>
    )
}

export default VenueHeader;