import {Field, FieldArray} from "formik";
import {Accordion, Col, Form, Row} from "react-bootstrap";
import IconButton from "../../../components/common/IconButton";
import React, {createRef, useEffect, useRef, useState} from "react";
import Flex from "../../../components/common/Flex";
import {Layer, Stage, Transformer} from "react-konva";
import Door from "./components/Door";
import TextShape from "./components/TextShape";
import StageShape from "./components/StageShape";
import {faShapes, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {v4 as uuid} from "uuid";
import Table from "./components/Table";
import Chair from "./components/Chair";

const RoomSection = ({values, setFieldValue, errors, t}) => {
    const [selectedId, setSelectedId] = useState(null);
    const shapeRef = useRef();
    const trRef = useRef();

    const checkDeselect = (e) => {
        const clickedOnEmpty = e.target === e.target.getStage();
        if (clickedOnEmpty) {
            setSelectedId(null);
        }
    };

    const getDefaultDimensions = (type) => {
        switch (type) {
            case "table":
                return {x: 100, y: 100, width: 100, height: 50};
            case "chair":
                return {x: 200, y: 200, size: 20};
            case "stage":
                return {x: 300, y: 100, width: 200, height: 100};
            case "door":
                return {x: 100, y: 300};
            case "text":
                return {};
            default:
                return null;
        }
    };

    const handleAddElement = (type, setFieldValue, index) => {
        const newElement = {
            id: uuid(),
            room: index,
            type,
            ...getDefaultDimensions(type),
        };
        const updatedElements = [
            ...(values?.rooms[index]?.elements || []),
            newElement,
        ];
        setFieldValue(`rooms.${index}.elements`, updatedElements);
    };

    const renderShape = (shape, roomIndex) => {
        const isSelected = selectedId === shape.id;
        const shapeNodeRef = isSelected ? shapeRef : createRef();
        const commonProps = {
            key: shape.id,
            ...shape,
            ref: shapeNodeRef,
            draggable: true,
            onClick: () => setSelectedId(shape.id),
            onDragEnd: (e) => handleDragEnd(e, shape.id, roomIndex),
        };

        switch (shape.type) {
            case "table":
                return <Table {...commonProps} />;
            case "chair":
                return <Chair {...commonProps} />;
            case "stage":
                return <StageShape {...commonProps} />;
            case "door":
                return <Door {...commonProps} />;
            case "text":
                return <TextShape {...commonProps} />;
            default:
                return null;
        }
    };

    const handleDragEnd = (e, id, roomIndex) => {
        const updatedElements = values?.rooms[roomIndex]?.elements.map((el) =>
            el.id === id ? {...el, x: e.target.x(), y: e.target.y()} : el
        );
        setFieldValue(`rooms.${roomIndex}.elements`, updatedElements);
    };

    const handleDeleteElement = (setFieldValue, roomIndex) => {
        if (selectedId) {
            const updatedElements = values.rooms[roomIndex].elements.filter(
                (el) => el.id !== selectedId
            );
            setSelectedId(null);
            setFieldValue(`rooms.${roomIndex}.elements`, updatedElements);
        }
    };

    useEffect(() => {
        if (selectedId && shapeRef.current && trRef.current) {
            trRef.current.nodes([shapeRef.current]);
            trRef.current.getLayer().batchDraw();
        }
    }, [selectedId]);

    return (
        <FieldArray
            name="rooms"
            render={(arrayHelpers) => (
                <>
                    <Accordion className={"mb-2"}>
                        {values?.rooms?.map((room, index) => (
                            <Accordion.Item eventKey={index} key={index} className={"mb-3"}>
                                <Accordion.Header>
                                    <Flex alignItems="center" wrap="wrap">
                                        <h5>{room?.name}</h5>
                                        <IconButton
                                            variant="falcon-default"
                                            icon="times"
                                            size="sm"
                                            className="position-absolute end-0 me-5"
                                            iconClassName="ms-1 text-danger"
                                            onClick={() => arrayHelpers.remove(index)}
                                        >
                                            {" "}
                                        </IconButton>
                                    </Flex>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.name')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={`rooms.${index}.name`}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            type={"text"}
                                                            value={field.value}
                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                            placeholder={t('fields.name')}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.width')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={`rooms.${index}.width`}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            type={"number"}
                                                            value={field.value}
                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                            placeholder={t('fields.width')}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.height')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={`rooms.${index}.height`}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            type={"number"}
                                                            value={field.value}
                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                            placeholder={t('fields.height')}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <Form.Label>{t('fields.capacity')} <span
                                                    className={"text-danger"}>*</span></Form.Label>
                                                <Field name={`rooms.${index}.capacity`}>
                                                    {({field}) => (
                                                        <Form.Control
                                                            type={"number"}
                                                            value={field.value}
                                                            onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                            placeholder={t('fields.capacity')}
                                                        />
                                                    )}
                                                </Field>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row className={"mt-3"}>
                                        <Col>
                                            <IconButton
                                                icon={faShapes}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("table", setFieldValue, index)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Table</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faShapes}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("chair", setFieldValue, index)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Chair</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faShapes}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("stage", setFieldValue, index)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Stage</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faShapes}
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleAddElement("door", setFieldValue, index)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Add Door</span>
                                            </IconButton>
                                            <IconButton
                                                icon={faTrashAlt}
                                                variant="danger"
                                                transform={"shrink-3"}
                                                size={"sm"}
                                                className={"me-3"}
                                                onClick={() => handleDeleteElement(setFieldValue, index)}
                                            >
                                                <span className={"d-none d-lg-inline-block"}>Delete Selected</span>
                                            </IconButton>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Stage
                                                width={room?.width}
                                                height={room?.height}
                                                onTouchStart={checkDeselect}
                                                onClick={checkDeselect}
                                                className="mt-3"
                                                style={{backgroundColor: "beige"}}
                                            >
                                                <Layer>
                                                    {room?.elements?.map((shape) =>
                                                        renderShape(shape, index)
                                                    )}
                                                    {selectedId && <Transformer ref={trRef}/>}
                                                </Layer>
                                            </Stage>
                                        </Col>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                    <IconButton
                        onClick={() =>
                            arrayHelpers.insert(values.rooms.length, {
                                name: "",
                                width: 0,
                                height: 0,
                                capacity: 0,
                                elements: []
                            })
                        }
                        variant="falcon-default"
                        size="sm"
                        icon="plus"
                        transform="shrink-3"
                    >
                        Add Room
                    </IconButton>
                </>
            )}
        />
    )
}

export default RoomSection