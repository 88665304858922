import {withPermission} from "../../../helpers/utils";
import React, {useEffect, useState} from "react";
import {Card, Col, Form, Row, Spinner, Tab, Tabs} from "react-bootstrap";
import Flex from "../../../components/common/Flex";
import {Field, Formik} from "formik";
import CSRFToken from "../../../helpers/CSRFToken";
import IconButton from "../../../components/common/IconButton";
import {faSave, faUsers, faUserTie} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";
import {api} from "../../../utils/api";
import {toast} from "react-toastify";
import paths from "../../../routes/paths";
import FormError from "../../errors/FormError";
import useQuery from "../../../hooks/useQuery";
import Select from "react-select";
import {getActivities} from "../../activity/actions/Activity";
import CustomDateInput from "../../../components/common/CustomDateInput";
import DatePicker from 'react-datepicker';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useAppContext} from "../../../providers/AppProvider";
import TinymceEditor from "../../../components/common/TinymceEditor";
import EnoticeAttachments from "./EnoticeAttachments";
import {withTranslation} from "react-i18next";

const EnoticeAddEdit = ({t, i18n}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({})
    const [activities, setActivities] = useState([])
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [selectedUnit, setSelectedUnit] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [sendBy, setSendBy] = useState(null);
    const [reminderBy, setReminderBy] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState([])
    const [formData, setFormData] = useState({
        activity: "",
        subject: "",
        send_by: "",
        reminder_by: "",
        priority: "normal",
        status: "draft",
        from_: {
            from_email: "",
            from_name: "",
            reply_to_email: "",
            reply_to_name: "",
        },
        content: {
            content: "",
            summary: "",
            attachments: []
        },
        receipient: {
            unit: "",
            membership: [],
            type: "members",
            status: {
                paid: false,
                unpaid: false
            },
            officers: [],
        }
    })

    const {
        config: {activity}
    } = useAppContext()
    const {id} = useParams()
    const navigate = useNavigate()
    const query = useQuery()

    const fetchActivities = async () => {
        query.set("page_size", "50")
        query.set("page", currentPage.toString())
        await api.get(`/activity/activity/?${query.toString()}`).then((res) => {
            setActivities([...activities, ...res?.data?.results])
        })
            .catch(() => {
            })
    }

    const getEnotice = async () => {
        setLoading(true)
        await api.get(`/enotice/enotice/${id}/`)
            .then(({data}) => {
                setSelectedActivity({value: data?.activity, label: data?.activity_name})
                setSendBy(new Date(data?.send_by));
                setReminderBy(new Date(data?.reminder_by));
                const updatedFrom = {
                    from_email: data?.from_data?.from_email,
                    from_name: data?.from_data?.from_name,
                    reply_to_email: data?.from_data?.reply_to_email,
                    reply_to_name: data?.from_data?.reply_to_name,
                }
                const updatedRecipient = {
                    membership: data?.receipient_data?.membership,
                    status: {
                        paid: data?.receipient_data?.status?.paid,
                        unpaid: data?.receipient_data?.status?.unpaid,
                        user_active: data?.receipient_data?.status?.user_active,
                        user_inactive: data?.receipient_data?.status?.user_inactive
                    },
                    unit: data?.receipient_data?.unit,
                    type: data?.receipient_data?.type
                }
                const updatedAttachments = data?.content_data?.attachment_set?.map((attachment) => ({
                    id: attachment?.id,
                    data: attachment?.data,
                    file: attachment?.data,
                    file_url: attachment?.file_url,
                }))
                const updatedContent = {
                    content: data?.content_data?.content,
                    summary: data?.content_data?.summary,
                    attachments: updatedAttachments,
                }
                setSelectedUnit(data?.receipient_data?.unit_data)
                setSelectedOptions(data?.receipient_data?.membership)
                setFormData({
                    ...data,
                    from_: updatedFrom,
                    receipient: updatedRecipient,
                    content: updatedContent
                })
            })
            .catch(err => console.error(err))
        setLoading(false)
    }

    useEffect(() => {
        if (id) getEnotice()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchActivities()
        // eslint-disable-next-line
    }, [currentPage]);

    const handleSubmit = async (e, values) => {
        e.preventDefault();
        setLoading(true);
        console.log(values)
        if (id)
            await api.patch(`/enotice/enotice/${id}/`, values)
                .then(res => {
                    toast.success(`${t('title')} ${t('updateSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate(paths.enoticeDetail.replace(":id", res?.data?.id));
                })
                .catch(err => {
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"})
                    setFormData(values)
                    setErrors(err?.response?.data)
                })
        else
            await api.post("/enotice/enotice/", values)
                .then(res => {
                    toast.success(`${t('title')} ${t('addSuccess', {ns: "common"})}`, {theme: 'colored'})
                    navigate(paths.enoticeDetail.replace(":id", res?.data?.id));
                })
                .catch(err => {
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"})
                    setFormData(values)
                    setErrors(err?.response?.data)
                })
        setLoading(false)
    }

    let activityOptions = activities?.map(activity => ({label: activity?.name, value: activity?.id}))

    activityOptions = [
        {label: "-------------------------", value: ""},
        ...activityOptions
    ]

    return loading ? (
        <Flex justifyContent="center" className="p-2 mb-2">
            <Spinner animation={'border'} variant={'primary'}/>
        </Flex>
    ) : (
        <Formik initialValues={formData} onSubmit={values => console.log(values)}>
            {({values, setFieldValue}) => (
                <Form>
                    <CSRFToken/>
                    <Card>
                        <Card.Header className="d-flex flex-between-center">
                            <Flex>
                                <IconButton
                                    onClick={() => navigate(-1)}
                                    variant="falcon-default"
                                    size="sm"
                                    icon="arrow-left"
                                />
                            </Flex>
                        </Card.Header>
                    </Card>
                    <Row>
                        <Col lg={6}>
                            <div className={"sticky-sidebar"}>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>{t('basicInformation')}</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t('activity')} <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"activity"}>
                                                        {({field}) => (
                                                            <Select
                                                                options={activityOptions}
                                                                value={selectedActivity}
                                                                placeholder={t('selectActivity')}
                                                                onChange={newValue => {
                                                                    setSelectedActivity(newValue)
                                                                    setFieldValue(field.name, newValue.value)
                                                                }}
                                                                onInputChange={newValue => {
                                                                    query.set("search", newValue)
                                                                    getActivities(query).then(res => setActivities(res?.results))
                                                                }}
                                                                onMenuScrollToBottom={event => {
                                                                    setCurrentPage(prevState => prevState + 1)
                                                                }}
                                                                classNamePrefix={"react-select"}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.activity}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t('subject')} <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"subject"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                type={"text"}
                                                                value={field.value}
                                                                placeholder={t('subject')}
                                                                onChange={e => setFieldValue(field.name, e.target.value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.subject}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t('sendBy')} <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"send_by"}>
                                                        {({field}) => (
                                                            <Row>
                                                                <DatePicker
                                                                    selected={sendBy}
                                                                    onChange={date => {
                                                                        setSendBy(date);
                                                                        setFieldValue(
                                                                            field.name,
                                                                            new Date(date).toISOString()
                                                                        );
                                                                    }}
                                                                    className="form-control"
                                                                    placeholderText={t('sendBy')}
                                                                    showTimeSelect
                                                                    fixedHeight
                                                                    timeIntervals={5}
                                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                                    customInput={
                                                                        <CustomDateInput
                                                                            formControlProps={{
                                                                                placeholder: 'MM/dd/yyyy HH:mm',
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Row>
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.send_by}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t('reminderBy')}</Form.Label>
                                                    <Field name={"reminder_by"}>
                                                        {({field}) => (
                                                            <Row>
                                                                <DatePicker
                                                                    selected={reminderBy}
                                                                    onChange={date => {
                                                                        setReminderBy(date);
                                                                        setFieldValue(
                                                                            field.name,
                                                                            new Date(date).toISOString()
                                                                        );
                                                                    }}
                                                                    className="form-control"
                                                                    placeholderText={t('reminderBy')}
                                                                    showTimeSelect
                                                                    fixedHeight
                                                                    timeIntervals={5}
                                                                    dateFormat="MM/dd/yyyy HH:mm"
                                                                    customInput={
                                                                        <CustomDateInput
                                                                            formControlProps={{
                                                                                placeholder: 'MM/dd/yyyy HH:mm',
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            </Row>
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.reminder_by}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t('priority')} <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"priority"}>
                                                        {({field}) => (
                                                            <Form.Select value={field.value}
                                                                         onChange={e => setFieldValue(field.name, e.target.value)}>
                                                                <option
                                                                    value={""}>{t('select', {ns: "common"})} {t('priority')}</option>
                                                                <option value={"normal"}>{t('normal')}</option>
                                                                <option value={"high"}>{t('high')}</option>
                                                                <option value={"critical"}>{t('critical')}</option>
                                                            </Form.Select>
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.priority}/>
                                                </Form.Group>
                                            </Col>
                                            <Col>
                                                <Form.Group>
                                                    <Form.Label>{t('status')} <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"status"}>
                                                        {({field}) => (
                                                            <Form.Select value={field.value}
                                                                         onChange={e => setFieldValue(field.name, e.target.value)}>
                                                                <option
                                                                    value={""}>{t('select', {ns: "common"})} {t('status')}</option>
                                                                <option value={"draft"}>Draft</option>
                                                                <option value={"pending"}>Pending</option>
                                                                <option value={"sent"}>Sent</option>
                                                            </Form.Select>
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.status}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>From</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>From email <span
                                                        className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"from_.from_email"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                placeholder={"From email"}
                                                                value={field.value}
                                                                onChange={e => setFieldValue(field.name, e.target.value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.from_?.from_email}/>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>From name</Form.Label>
                                                    <Field name={"from_.from_name"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                placeholder={"From name"}
                                                                value={field.value}
                                                                onChange={e => setFieldValue(field.name, e.target.value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.from_?.from_name}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Reply to email <span className={"text-danger"}>*</span></Form.Label>
                                                    <Field name={"from_.reply_to_email"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                placeholder={"Reply to email"}
                                                                value={field.value}
                                                                onChange={e => setFieldValue(field.name, e.target.value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.from_?.reply_to_email}/>
                                                </Form.Group>
                                            </Col>
                                            <Col lg={6}>
                                                <Form.Group>
                                                    <Form.Label>Reply to name</Form.Label>
                                                    <Field name={"from_.reply_to_name"}>
                                                        {({field}) => (
                                                            <Form.Control
                                                                placeholder={"Reply to name"}
                                                                value={field.value}
                                                                onChange={e => setFieldValue(field.name, e.target.value)}
                                                            />
                                                        )}
                                                    </Field>
                                                    <FormError error={errors?.from_?.reply_to_name}/>
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <FormError error={errors?.from}/>
                                    </Card.Body>
                                </Card>
                                <EnoticeAttachments setFieldValue={setFieldValue} values={values} errors={errors}/>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className={"sticky-sidebar"}>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>Recipient Information</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        {activity &&
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>Unit <span
                                                            className={"text-danger"}>*</span></Form.Label>
                                                        <Field name={"receipient.unit"}>
                                                            {({field}) => (
                                                                <Form.Switch
                                                                    label={activities?.find(act => act.id === activity)?.name}
                                                                    checked={field.value === selectedUnit?.id}
                                                                    onChange={e => {
                                                                        if (e.target.checked) {
                                                                            setSelectedUnit(activities?.find(act => act.id === activity))
                                                                            setFieldValue(field.name, activities?.find(act => act.id === activity)?.id)
                                                                        } else {
                                                                            setSelectedUnit(null)
                                                                            setFieldValue(field.name, "")
                                                                        }
                                                                    }}
                                                                />
                                                            )}
                                                        </Field>
                                                        <FormError error={errors?.receipient?.unit}/>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col>
                                                <Tabs
                                                    id="controlled-tab-example"
                                                    justify
                                                    activeKey={values?.type}
                                                    onSelect={(k) => setFieldValue("receipient.type", k)}
                                                >
                                                    <Tab eventKey="members"
                                                         title={<><FontAwesomeIcon icon={faUsers}/> Notify Members</>}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Membership Status <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Row>
                                                                        <Col lg={4}>
                                                                            <Field name={"receipient.status.paid"}>
                                                                                {({field}) => (
                                                                                    <>
                                                                                        <Form.Switch
                                                                                            label={"Paid"}
                                                                                            checked={field.value}
                                                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                            <FormError
                                                                                error={errors?.receipient?.status?.paid}/>
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            <Field name={"receipient.status.unpaid"}>
                                                                                {({field}) => (
                                                                                    <>
                                                                                        <Form.Switch
                                                                                            label={"Unpaid"}
                                                                                            checked={field.value}
                                                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                            <FormError
                                                                                error={errors?.receipient?.status?.unpaid}/>
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>User Status <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Row>
                                                                        <Col lg={4}>
                                                                            <Field
                                                                                name={"receipient.status.user_active"}>
                                                                                {({field}) => (
                                                                                    <>
                                                                                        <Form.Switch
                                                                                            label={"Active"}
                                                                                            checked={field.value}
                                                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                            <FormError
                                                                                error={errors?.receipient?.status?.user_active}/>
                                                                        </Col>
                                                                        <Col lg={4}>
                                                                            <Field
                                                                                name={"receipient.status.user_inactive"}>
                                                                                {({field}) => (
                                                                                    <>
                                                                                        <Form.Switch
                                                                                            label={"Inactive"}
                                                                                            checked={field.value}
                                                                                            onChange={e => setFieldValue(field.name, e.target.checked)}
                                                                                        />
                                                                                    </>
                                                                                )}
                                                                            </Field>
                                                                            <FormError
                                                                                error={errors?.receipient?.status?.user_inactive}/>
                                                                        </Col>
                                                                    </Row>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={12}>
                                                                <Form.Group>
                                                                    <Form.Label>Membership Option <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Field name={"receipient.membership"}>
                                                                        {({field}) => (
                                                                            selectedUnit?.membership?.membership_options?.map(item => (
                                                                                <Form.Switch
                                                                                    key={item?.id}
                                                                                    label={item?.name}
                                                                                    checked={selectedOptions.includes(item?.id)}
                                                                                    onChange={e => {
                                                                                        setSelectedOptions(prevState => [...prevState, item?.id])
                                                                                        setFieldValue(field.name, [...selectedOptions, item?.id])
                                                                                    }}
                                                                                />
                                                                            ))
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors?.receipient?.membership}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Tab>
                                                    <Tab eventKey="officers"
                                                         title={<><FontAwesomeIcon icon={faUserTie}/> Notify
                                                             Officers</>}>
                                                        <Row>
                                                            <Col>
                                                                <Form.Group>
                                                                    <Form.Label>Officers <span
                                                                        className={"text-danger"}>*</span></Form.Label>
                                                                    <Field name={"receipient.officers"}>
                                                                        {({field}) => (
                                                                            selectedUnit?.positions?.map(item => (
                                                                                <Form.Switch
                                                                                    label={`${item?.name} - ${item?.officers?.filter(o => new Date(o?.term_end_date) > new Date()).map(officer => officer?.user_full_name)}`}
                                                                                    key={item?.id}
                                                                                    value={item?.id}
                                                                                    onChange={e => setFieldValue(field.name, e.target.checked)}
                                                                                />
                                                                            ))
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors?.receipient?.officers}/>
                                                                </Form.Group>
                                                            </Col>
                                                        </Row>
                                                    </Tab>
                                                </Tabs>
                                                <FormError error={errors?.receipient_}/>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                                <Card className={"mt-3"}>
                                    <Card.Header>
                                        <h5>Content</h5>
                                    </Card.Header>
                                    <Card.Body>
                                        <Form.Group>
                                            <Form.Label>Summary</Form.Label>
                                            <Field name={"content.summary"}>
                                                {({field}) => (
                                                    <Form.Control
                                                        as={"textarea"}
                                                        value={field.value}
                                                        onChange={e => setFieldValue(field.name, e.target.value)}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.content?.summary}/>
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>Body <span className={"text-danger"}>*</span></Form.Label>
                                            <Field name={"content.content"}>
                                                {({field}) => (
                                                    <TinymceEditor
                                                        value={field.value}
                                                        handleChange={newValue => setFieldValue(field.name, newValue)}
                                                    />
                                                )}
                                            </Field>
                                            <FormError error={errors?.content?.content}/>
                                        </Form.Group>
                                        <FormError error={errors?.content_}/>
                                    </Card.Body>
                                </Card>
                            </div>

                        </Col>
                    </Row>
                    <Card className={"mt-3"}>
                        <Card.Footer>
                            <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
                                <p className={"text-danger"}>* Mandatory Field</p>
                                <IconButton
                                    icon={faSave}
                                    onClick={e => handleSubmit(e, values)}
                                >
                        <span className="d-none d-sm-inline-block ms-1">
                          Save
                        </span>
                                </IconButton>
                            </Flex>
                        </Card.Footer>
                    </Card>
                </Form>
            )}
        </Formik>
    )
}

export default withPermission(withTranslation(["enotice", "common"])(EnoticeAddEdit), ["enotice.add_enotice", "enotice.change_enotice"])