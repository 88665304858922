import {withPermission} from "../../helpers/utils";
import {withTranslation} from "react-i18next";
import React, {Fragment, useCallback, useEffect, useState} from "react";
import {Card, Col, Form, Row, Spinner} from "react-bootstrap";
import CollaborationHeader from "./CollaborationHeader";
import {api} from "../../utils/api";
import useQuery from "../../hooks/useQuery";
import Flex from "../../components/common/Flex";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import CollaborationGrid from "./CollaborationGrid";
import AdvanceTablePagination from "../../components/common/advance-table/AdvanceTablePagination";
import {debounce} from "lodash";
import {useNavigate} from "react-router-dom";

const CollaborationCenter = ({t, i18n}) => {
    const [collaborations, setCollaborations] = useState([])
    const [loading, setLoading] = useState(false);
    const [itemsPerPage, setItemsPerPage] = useState(10)
    const [length, setLength] = useState(0);
    const [count, setCount] = useState(0);

    const query = useQuery()
    const navigate = useNavigate();

    const fetchCollaborations = async (q) => {
        setLoading(true);
        navigate(`?${query.toString()}`)
        await api.get(`/collaboration/collaboration/?${q.toString()}`).then(res => {
            setCollaborations(res?.data.results)
            setCount(res?.data?.count)
        })
        setLoading(false)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const delayedLoadItems = useCallback(debounce(fetchCollaborations, 600), []);

    useEffect(() => {
        query.set("page_size", itemsPerPage.toString())
        fetchCollaborations(query)
        setLength(collaborations?.length)
        // eslint-disable-next-line
    }, [itemsPerPage]);

    return (
        <Fragment>
            <Row>
                <Col lg={12}>
                    <CollaborationHeader query={query} fetch={delayedLoadItems} t={t}/>
                </Col>
            </Row>
            <Row className="mb-3 g-3">
                {loading ? (
                    <Flex justifyContent="center" className="p-2 mb-2">
                        <Spinner animation={'border'} variant={'primary'}/>
                    </Flex>
                ) : (
                    collaborations?.length > 0 ? (
                        collaborations?.map(collaboration => (
                            <Col key={collaboration.id} md={6} xxl={4}>
                                <CollaborationGrid collaboration={collaboration} t={t}/>
                            </Col>
                        ))
                    ) : (
                        <Card className="bg-transparent shadow-none">
                            <Card.Body className="border-2 border-dashed border-400 border rounded text-center py-5">
                                <div className="fs-10">
                                    <FontAwesomeIcon
                                        icon="exclamation-triangle"
                                        className="fs-3 mb-3"
                                    />
                                    <h5>No Collaborations Found!</h5>
                                    <p className="mb-0">
                                        Your search did not match any Collaboration. Please try again.
                                    </p>
                                </div>
                            </Card.Body>
                        </Card>
                    )
                )}
            </Row>
            {collaborations?.length > 0 && (
                <Card>
                    <Card.Body>
                        <Row className="g-3 flex-column flex-sm-row flex-center justify-content-sm-between">
                            <Col
                                xs="auto"
                                as={Flex}
                                alignItems="center"
                                className="d-none d-sm-flex"
                            >
                                <small className="d-none d-sm-block me-2">Show:</small>
                                <Form.Select
                                    size="sm"
                                    value={itemsPerPage}
                                    onChange={({target}) => {
                                        setItemsPerPage(target.value);
                                    }}
                                    style={{maxWidth: '4.875rem'}}
                                >
                                    <option value={10}>10</option>
                                    <option value={25}>25</option>
                                    <option value={50}>50</option>
                                    <option value={length}>All</option>
                                </Form.Select>
                            </Col>
                            <Col xs="auto" as={Flex}>
                                <AdvanceTablePagination
                                    count={count}
                                    query={query}
                                    itemsPerPage={itemsPerPage}
                                    length={length}
                                    fetch={fetchCollaborations}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </Fragment>
    )
}

export default withPermission(withTranslation(["collaboration", "common"])(CollaborationCenter), "collaboration.view_collaboration")