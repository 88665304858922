import React, {
    Fragment,
    useEffect,
    useRef,
    useState
} from 'react';
import {Card, Col, Form, Row, Spinner} from 'react-bootstrap';
import CardHeader from 'react-bootstrap/CardHeader';
import Flex from '../../../../components/common/Flex';
import IconButton from '../../../../components/common/IconButton';
import {faSave} from '@fortawesome/free-solid-svg-icons';
import {Field, Formik} from 'formik';
import Avatar from '../../../../components/common/Avatar';
import {isIterableArray, withPermission} from '../../../../helpers/utils';
import FalconDropzone from '../../../../components/common/FalconDropzone';
import cloudUpload from '../../../../assets/img/icons/cloud-upload.svg';
import avatarImg from '../../../../assets/img/team/avatar.png';
import Select, {components} from 'react-select';
import DatePicker from 'react-datepicker';
import useQuery from '../../../../hooks/useQuery';
import {getGroups, getPermissions} from '../../actions/GroupsPermissions';
import {getActivities} from '../../../activity/actions/Activity';
import {api} from '../../../../utils/api';
import {toast} from 'react-toastify';
import {useNavigate, useParams} from 'react-router-dom';
import FormError from '../../../errors/FormError';
import {format} from 'date-fns';
import CSRFToken from '../../../../helpers/CSRFToken';
import {useAppContext} from '../../../../providers/AppProvider';
import {useAuthWizardContext} from '../../../../providers/AuthWizardProvider';
import {withTranslation} from "react-i18next";
import TinymceEditor from "../../../../components/common/TinymceEditor";
import TooltipBadge from "../../../../components/common/TooltipBadge";
import {getUser} from "../../actions/Users";
import paths from "../../../../routes/paths";

const NewEditUser = ({t, i18n}) => {
    const {user} = useAuthWizardContext();
    const [selectInput, setSelectInput] = useState("");
    const [formData, setFormData] = useState({
        password: '',
        email: '',
        username: '',
        first_name: '',
        last_name: '',
        is_active: true,
        is_staff: false,
        is_superuser: false,
        is_activity: false,
        is_company: false,
        number: '',
        avatar: null,
        gender: null,
        birth_date: null,
        activity: null,
        company: null,
        groups: [],
        user_permissions: [],
        account_settings: {
            visibility: "followers",
            tag: "members",
            show_followers: true,
            show_emails: true,
            show_experience: false,
            phone_visible: true,
            user_follow: false,
            newsletter_subscribed: true,
        }
    });
    const [loading, setLoading] = useState(true);
    const [avatar, setAvatar] = useState([
        ...(user.avatar ? user.avatar : []),
        {src: avatarImg}
    ]);
    const [errors, setErrors] = useState({});
    const [date, setDate] = useState(null);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState(null);
    const [permissions, setPermissions] = useState([]);
    const [permissionsPage, setPermissionsPage] = useState(1)
    const [selectedPermissions, setSelectedPermissions] = useState(null);
    const [activities, setActivities] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);
    const [companies, setCompanies] = useState([])
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [companyPage, setCompanyPage] = useState(1)
    const [currentPage, setCurrentPage] = useState(1);

    const {
        config: {isAuthenticated, group},
        setConfig,
    } = useAppContext();
    const navigate = useNavigate();
    const query = useQuery();
    const activityQuery = useQuery();
    const {id} = useParams();
    const isAllSelected = useRef(false);
    const selectAllLabel = useRef("Select all");
    const allOption = {value: "*", label: selectAllLabel.current};

    const filterOptions = (options, input) =>
        options?.filter(({label}) =>
            label.toLowerCase().includes(input.toLowerCase())
        );

    const comparator = (v1, v2) => (v1.value) - (v2.value);

    const fetchCompanies = async () => {
        query.set("page_size", "50")
        query.set("page", companyPage.toString())
        await api.get(`/company/?${query.toString()}`).then(res => setCompanies([...companies, ...res?.data?.results]))
    }

    const fetchUser = async () => {
        setLoading(true);
        getUser(id)
            .then((res) => {
                const updatedSettings = {
                    visibility: res?.settings?.visibility,
                    tag: res?.settings?.tag,
                    show_followers: res?.settings?.show_followers,
                    show_emails: res?.settings?.show_emails,
                    show_experience: res?.settings?.show_experience,
                    phone_visible: res?.settings?.phone_visible,
                    user_follow: res?.settings?.user_follow,
                    newsletter_subscribed: res?.settings?.newsletter_subscribed,
                }
                setFormData({...res, account_settings: updatedSettings});
                setAvatar([{src: res?.url_path}]);
                setDate(new Date(res?.birth_date));
                res?.is_activity && setSelectedActivity({
                    label: res?.activity_name,
                    value: res?.activity,
                });
                res?.is_company && setSelectedCompany({
                    label: res?.company_name,
                    value: res?.company,
                });
                setSelectedGroups(
                    res?.groups_id?.map((group, index) => ({
                        label: res?.groups_name[index],
                        value: group,
                    }))
                );
                setSelectedPermissions(
                    res?.user_permissions?.map((group, index) => ({
                        label: res?.user_permissions_names[index],
                        value: group,
                    }))
                );
            })
            .catch((error) => {
                if (error?.response?.status === 404) navigate(paths.error404);
                if (error?.response?.status === 500) navigate(paths.error500);
                if (error?.response?.status === 403)
                    setConfig("isAuthenticated", !isAuthenticated);
            });
        setLoading(false);
    }

    useEffect(() => {
        if (id) fetchUser()
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(true);
        getGroups(query)
            .then(res => setGroups(res?.results))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setLoading(true);
        query.set("page_size", "50")
        query.set("page", permissionsPage.toString())
        getPermissions(query)
            .then(res => setPermissions(res?.results))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [permissionsPage]);

    useEffect(() => {
        setLoading(true);
        activityQuery.set('page_size', '50');
        activityQuery.set('page', currentPage);
        getActivities(activityQuery)
            // eslint-disable-next-line no-unsafe-optional-chaining
            .then(res => setActivities([...activities, ...res?.results]))
            .catch(() => {
            });
        setLoading(false);
        // eslint-disable-next-line
    }, [currentPage]);

    useEffect(() => {
        fetchCompanies()
        // eslint-disable-next-line
    }, [companyPage]);

    let groupOptions = groups.map(group => ({
        label: group.name,
        value: group.id
    }));

    groupOptions = [
        {label: '---------------------------', value: ''},
        ...groupOptions
    ];

    let permissionOptions = permissions.map(permission => ({
        label: permission.name,
        value: permission.id
    }));

    let activityOptions = activities.map(activity => ({
        label: activity.name,
        value: activity.id
    }));

    activityOptions = [
        {label: '---------------------------', value: ''},
        ...activityOptions
    ];

    let companyOptions = companies.map(company => ({label: company?.name, value: company?.id}))

    companyOptions = [
        {label: '---------------------------', value: ''},
        ...companyOptions
    ]

    let filteredOptions = filterOptions(permissionOptions, selectInput);
    let filteredSelectedOptions = filterOptions(selectedPermissions, selectInput);

    const handleSubmit = async (e, values) => {
        e.preventDefault();
        setLoading(true);
        if (id) {
            let fd = new FormData();
            Object.keys(formData).forEach((key) => {
                fd.append(key, formData[key]);
            });
            if (!formData.avatar) fd.delete("avatar");
            if (!formData.birth_date) fd.delete("birth_date");
            if (!formData.activity) fd.delete("activity");
            if (!formData.company) fd.delete("company");
            fd.delete("address");
            fd.delete("last_login");
            fd.delete("groups");
            fd.delete("avatar");
            fd.delete("user_permissions");
            if (avatar[0].base64) {
                fd.append("avatar", avatar[0].base64);
            }
            if (selectedCompany)
                fd.append("company", selectedCompany.value)
            if (selectedGroups) {
                selectedGroups.forEach((group) => fd.append("groups", group.value));
            }
            if (selectedPermissions) {
                selectedPermissions.forEach((permission) =>
                    fd.append("user_permissions", permission.value)
                );
            }
            if (formData.cover) {
                fd.append("cover", formData.cover)
            }
            if (date)
                fd.append(
                    "birth_date",
                    date &&
                    new Date(date.getTime() - date.getTimezoneOffset() * 60000)
                        .toISOString()
                        .split("T")[0]
                );
            if (selectedActivity) fd.append("activity", selectedActivity.value);
            await api
                .patch(`/account/user/${id}/`, fd, {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
                    }
                })
                .then((res) => {
                    toast.success(`${t('title')} ${res?.data?.username} ${t('updateSuccess', {ns: "common"})}`, {
                        theme: "colored",
                    });
                    navigate("/accounts/user");
                })
                .catch((err) => {
                    setErrors(err?.response?.data);
                    toast.error(`${t('error', {ns: "common"})}`, {theme: "colored"});
                });
        } else {
            setFormData(values);
            let fd = new FormData();
            Object.keys(values).forEach(key => {
                fd.append(key, values[key]);
            });
            if (!values.avatar) fd.delete('avatar');
            if (!values.birth_date) fd.delete('birth_date');
            if (!values.activity) fd.delete('activity');
            if (!values.company) fd.delete('company');
            fd.delete('groups');
            fd.delete('avatar');
            fd.delete('user_permissions');
            if (avatar[0].base64) {
                fd.append("avatar", avatar[0].base64);
            }
            if (values.groups) {
                values.groups.forEach(group => fd.append('groups', group));
            }
            if (values.user_permissions) {
                values.user_permissions.forEach(permission =>
                    fd.append('user_permissions', permission)
                );
            }
            await api
                .post('/account/user/', fd, {
                    headers: {
                        "Content-Type": "multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW"
                    }
                })
                .then((res) => {
                    toast.success(`${t('title')} ${res?.data?.username} ${t('addSuccess', {ns: "common"})}`, {theme: 'colored'});
                    navigate('/accounts/user');
                })
                .catch(err => {
                    setErrors(err?.response?.data);
                    toast.error(`${t('error', {ns: "common"})}`, {theme: 'colored'});
                });
        }
        setLoading(false);
    };

    const Option = (props) => (
        <components.Option {...props}>
            {props.value === "*" &&
            !isAllSelected.current &&
            filteredSelectedOptions?.length > 0 ? (
                <input
                    key={props.value}
                    type="checkbox"
                    ref={(input) => {
                        if (input) input.indeterminate = true;
                    }}
                />
            ) : (
                <input
                    key={props.value}
                    type="checkbox"
                    checked={props.isSelected || isAllSelected.current}
                    onChange={() => {
                    }}
                />
            )}
            <label style={{marginLeft: "5px"}}>{props.label}</label>
        </components.Option>
    );

    const Input = (props) => (
        <>
            {selectInput.length === 0 ? (
                <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                    {props.children}
                </components.Input>
            ) : (
                <div style={{border: "1px dotted gray"}}>
                    <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
                        {props.children}
                    </components.Input>
                </div>
            )}
        </>
    );

    const customFilterOption = ({value, label}, input) =>
        (value !== "*" && label.toLowerCase().includes(input.toLowerCase())) ||
        (value === "*" && filteredOptions?.length > 0);

    const onInputChange = (
        inputValue,
        event
    ) => {
        if (event.action === "input-change") {
            setSelectInput(inputValue)
            query.set("search", inputValue)
            getPermissions(query).then(r => setPermissions(r?.results))
                .catch(() => {
                })
        } else if (event.action === "menu-close" && selectInput !== "")
            setSelectInput("");
    };

    const onKeyDown = (e) => {
        if ((e.key === " " || e.key === "Enter") && !selectInput)
            e.preventDefault();
    };

    const handleChange = (selected) => {
        if (
            selected.length > 0 &&
            !isAllSelected.current &&
            (selected[selected.length - 1].value === allOption.value ||
                JSON.stringify(filteredOptions) ===
                JSON.stringify(selected.sort(comparator)))
        )
            return setSelectedPermissions(
                [
                    ...(selectedPermissions ?? []),
                    ...permissionOptions.filter(
                        ({label}) =>
                            label.toLowerCase().includes(selectInput?.toLowerCase()) &&
                            (selectedPermissions ?? []).filter((opt) => opt.label === label)
                                .length === 0
                    ),
                ].sort(comparator)
            );
        else if (
            selected.length > 0 &&
            selected[selected.length - 1].value !== allOption.value &&
            JSON.stringify(selected.sort(comparator)) !==
            JSON.stringify(filteredOptions)
        )
            return setSelectedPermissions(selected);
        else
            return setSelectedPermissions([
                ...selectedPermissions?.filter(
                    ({label}) =>
                        !label.toLowerCase().includes(selectInput?.toLowerCase())
                ),
            ]);
    };

    const customStyles = {
        option: (styles, {isSelected, isFocused}) => {
            return {
                ...styles,
                backgroundColor:
                    isSelected && !isFocused
                        ? null
                        : isFocused && !isSelected
                            ? styles.backgroundColor
                            : isFocused && isSelected
                                ? "#DEEBFF"
                                : null,
                color: isSelected ? null : null,
            };
        },
        menu: (def) => ({...def, zIndex: 9999}),
    };

    return (
        <Fragment>
            {loading ? (
                <Flex
                    justifyContent="center"
                    alignItems={'center'}
                    className="p-2 mb-2"
                >
                    <Spinner animation={'border'} variant={'primary'}/>
                </Flex>
            ) : (
                <>
                    <Formik
                        initialValues={formData}
                        onSubmit={values => {
                            console.log(values);
                        }}
                    >
                        {({values, setFieldValue}) => (
                            <>
                                <Form>
                                    <CSRFToken/>
                                    <Row className={'g-3 mt-1 mb-3'}>
                                        <Col xxl={8} xl={12}>
                                            <Row className="g-3">
                                                <Col xs={12}>
                                                    <Card>
                                                        <Card.Header>
                                                            <h5 className="mb-0 text-muted">
                                                                {t("fields.basic")}
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body className="bg-light pb-0">
                                                            <Form.Group>
                                                                <Row className="mb-3">
                                                                    <Col md="auto">
                                                                        <Avatar
                                                                            size="4xl"
                                                                            src={
                                                                                isIterableArray(avatar)
                                                                                    ? avatar[0]?.base64 || avatar[0]?.src
                                                                                    : ''
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col md>
                                                                        <Field name="avatar">
                                                                            {() => (
                                                                                <FalconDropzone
                                                                                    files={avatar}
                                                                                    onChange={files => {
                                                                                        setAvatar(files);
                                                                                        setFieldValue('avatar', files[0]);
                                                                                    }}
                                                                                    multiple={false}
                                                                                    accept="image/*"
                                                                                    placeholder={
                                                                                        <>
                                                                                            <Flex
                                                                                                justifyContent="center">
                                                                                                <img
                                                                                                    src={cloudUpload}
                                                                                                    alt=""
                                                                                                    width={25}
                                                                                                    className="me-2"
                                                                                                />
                                                                                                <p className="fs-0 mb-0 text-700">
                                                                                                    {t('fields.upload')}
                                                                                                </p>
                                                                                            </Flex>
                                                                                            <p className="mb-0 w-75 mx-auto text-400">
                                                                                                {t('fields.uploadDesc')}
                                                                                            </p>
                                                                                        </>
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormError error={errors.avatar}/>
                                                                    </Col>
                                                                </Row>
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Label>{t('fields.username')}: <span
                                                                    className={"text-danger"}>*</span></Form.Label>
                                                                <Field name="username">
                                                                    {({field}) => (
                                                                        <Form.Control
                                                                            type="text"
                                                                            name="username"
                                                                            placeholder={t('fields.username')}
                                                                            errors={errors}
                                                                            formGroupProps={{
                                                                                className: 'mb-3'
                                                                            }}
                                                                            onChange={e => {
                                                                                setFieldValue(
                                                                                    field.name,
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={field.value}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <FormError error={errors.username}/>
                                                            </Form.Group>
                                                            <Row>
                                                                <Col md={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>{t('fields.first_name')}: <span
                                                                            className={"text-warning"}>*</span></Form.Label>
                                                                        <Field name="first_name">
                                                                            {({field}) => (
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    label={t('fields.first_name')}
                                                                                    name="first_name"
                                                                                    placeholder={t('fields.first_name')}
                                                                                    errors={errors}
                                                                                    formGroupProps={{
                                                                                        className: 'mb-3'
                                                                                    }}
                                                                                    onChange={e => {
                                                                                        setFieldValue(
                                                                                            field.name,
                                                                                            e.target.value
                                                                                        );
                                                                                    }}
                                                                                    value={field.value}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormError error={errors.first_name}/>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>{t('fields.last_name')}: <span
                                                                            className={"text-warning"}>*</span></Form.Label>
                                                                        <Field name="last_name">
                                                                            {({field}) => (
                                                                                <Form.Control
                                                                                    type="text"
                                                                                    label={t('fields.last_name')}
                                                                                    name="last_name"
                                                                                    placeholder={t('fields.last_name')}
                                                                                    errors={errors}
                                                                                    formGroupProps={{
                                                                                        className: 'mb-3'
                                                                                    }}
                                                                                    onChange={e => {
                                                                                        setFieldValue(
                                                                                            field.name,
                                                                                            e.target.value
                                                                                        );
                                                                                    }}
                                                                                    value={field.value}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormError error={errors.last_name}/>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            <Form.Group className={'mb-3'}>
                                                                <Row>
                                                                    <Form.Label>{t('fields.date_of_birth')}:</Form.Label>
                                                                </Row>
                                                                <Row>
                                                                    <Field name="birth_date">
                                                                        {({field}) => (
                                                                            <DatePicker
                                                                                selected={date}
                                                                                onChange={date => {
                                                                                    setDate(date);
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        format(date, 'yyyy-MM-dd')
                                                                                    );
                                                                                }}
                                                                                formatWeekDay={day => day.slice(0, 3)}
                                                                                className="form-control"
                                                                                dateFormat="MM-dd-yyyy"
                                                                                placeholderText={t('fields.MMDDYYYY')}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                </Row>
                                                                <FormError error={errors.birth_date}/>
                                                            </Form.Group>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col xs={12}>
                                                    <Card className={'pb-3'}>
                                                        <Card.Header>
                                                            <h5 className="mb-0 text-muted">
                                                                {t('fields.contact')}
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body className="bg-light pb-0">
                                                            <Form.Group>
                                                                <Form.Label>{t('fields.email')}: <span
                                                                    className={"text-warning"}>*</span></Form.Label>
                                                                <Field name="email">
                                                                    {({field}) => (
                                                                        <Form.Control
                                                                            type="email"
                                                                            label={t('fields.email')}
                                                                            name="email"
                                                                            placeholder={t('fields.email')}
                                                                            errors={errors}
                                                                            formGroupProps={{
                                                                                className: 'mb-3'
                                                                            }}
                                                                            onChange={e => {
                                                                                setFieldValue(
                                                                                    field.name,
                                                                                    e.target.value
                                                                                );
                                                                            }}
                                                                            value={field.value}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <FormError error={errors.email}/>
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Label>{t('fields.phone')}:</Form.Label>
                                                                <Field name="number">
                                                                    {({field}) => (
                                                                        <Form.Control
                                                                            type="number"
                                                                            label="Phone"
                                                                            name="number"
                                                                            placeholder={t('fields.phone')}
                                                                            errors={errors}
                                                                            formGroupProps={{
                                                                                className: 'mb-3'
                                                                            }}
                                                                            formControlProps={{
                                                                                className: 'input-spin-none'
                                                                            }}
                                                                            onChange={e =>
                                                                                setFieldValue(
                                                                                    field.name,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                            value={field.value}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <FormError error={errors.number}/>
                                                            </Form.Group>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                                <Col lg={12}>
                                                    <Card>
                                                        <Card.Header>
                                                            <h5 className="mb-0 text-muted">
                                                                {t('fields.extra')}
                                                            </h5>
                                                        </Card.Header>
                                                        <Card.Body className="bg-light pb-0">
                                                            <Row>
                                                                <Col lg={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>{t('fields.heading')}:</Form.Label>
                                                                        <Field name={'heading'}>
                                                                            {({field}) => (
                                                                                <Form.Control
                                                                                    type={'text'}
                                                                                    name={'heading'}
                                                                                    value={field.value}
                                                                                    onChange={e =>
                                                                                        setFieldValue(
                                                                                            field.name,
                                                                                            e.target.value
                                                                                        )
                                                                                    }
                                                                                    placeholder={t('fields.heading')}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormError error={errors?.heading}/>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={6}>
                                                                    <Form.Group>
                                                                        <Form.Label>{t('fields.cover')}:</Form.Label>
                                                                        <Field name={'cover'}>
                                                                            {({field}) => (
                                                                                <Form.Control
                                                                                    type={'file'}
                                                                                    name={'cover'}
                                                                                    onChange={e =>
                                                                                        setFieldValue(
                                                                                            field.name,
                                                                                            e.target.files[0]
                                                                                        )
                                                                                    }
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormError error={errors?.cover}/>
                                                                    </Form.Group>
                                                                </Col>
                                                                <Form.Group className={'mb-3'}>
                                                                    <Form.Label>{t('fields.intro')}:</Form.Label>
                                                                    <Field name={'introduction'}>
                                                                        {({field}) => (
                                                                            <TinymceEditor
                                                                                value={field.value}
                                                                                handleChange={newValue => setFieldValue(field.name, newValue)}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors?.introduction}/>
                                                                </Form.Group>
                                                            </Row>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xxl={4} xl={12}>
                                            <div className={'sticky-sidebar'}>
                                                <Card>
                                                    <CardHeader>
                                                        <h5 className="mb-0 text-muted">{t('fields.user_status')} <span
                                                            className={"text-warning"}>*</span></h5>
                                                    </CardHeader>
                                                    <Card.Body>
                                                        <Form.Group>
                                                            <Field name="is_active">
                                                                {({field}) => (
                                                                    <Form.Switch
                                                                        label={t('fields.active')}
                                                                        name="is_active"
                                                                        errors={errors}
                                                                        formGroupProps={{
                                                                            className: 'mb-3'
                                                                        }}
                                                                        onChange={e =>
                                                                            setFieldValue(
                                                                                field.name,
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors.is_active}/>
                                                        </Form.Group>
                                                        {group?.indexOf(
                                                            process.env.REACT_APP_ADMIN_GROUP_NAME
                                                        ) !== -1 && (
                                                            <>
                                                                <Form.Group>
                                                                    <Field name="is_staff">
                                                                        {({field}) => (
                                                                            <Form.Switch
                                                                                label={t('fields.staff')}
                                                                                name="is_staff"
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e =>
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.checked
                                                                                    )
                                                                                }
                                                                                checked={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.is_staff}/>
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Field name="is_superuser">
                                                                        {({field}) => (
                                                                            <Form.Switch
                                                                                label={t('fields.super_user')}
                                                                                name="is_superuser"
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e =>
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.checked
                                                                                    )
                                                                                }
                                                                                checked={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.is_superuser}/>
                                                                </Form.Group>
                                                                <Form.Group>
                                                                    <Field name="is_activity">
                                                                        {({field}) => (
                                                                            <Form.Switch
                                                                                label={t('fields.activity')}
                                                                                name="is_activity"
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e =>
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.checked
                                                                                    )
                                                                                }
                                                                                checked={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.is_activity}/>
                                                                </Form.Group>
                                                                {values?.is_activity && (
                                                                    <Form.Group>
                                                                        <Field name="activity">
                                                                            {({field}) => (
                                                                                <Select
                                                                                    options={activityOptions}
                                                                                    onMenuScrollToBottom={e => {
                                                                                        console.log(e);
                                                                                        setCurrentPage(page => page + 1);
                                                                                    }}
                                                                                    onInputChange={e => {
                                                                                        activityQuery.set('search', e);
                                                                                        getActivities(activityQuery).catch(
                                                                                            () => {
                                                                                            }
                                                                                        );
                                                                                    }}
                                                                                    placeholder={`${t('select', {ns: "common"})} ${t('fields.activity')}`}
                                                                                    classNamePrefix="react-select"
                                                                                    value={selectedActivity}
                                                                                    onChange={value => {
                                                                                        setFieldValue(
                                                                                            field.name,
                                                                                            value.value
                                                                                        );
                                                                                        setSelectedActivity(value);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormError error={errors.activity}/>
                                                                    </Form.Group>
                                                                )}
                                                                <Form.Group>
                                                                    <Field name="is_company">
                                                                        {({field}) => (
                                                                            <Form.Switch
                                                                                label={t('fields.company')}
                                                                                name="is_company"
                                                                                errors={errors}
                                                                                formGroupProps={{
                                                                                    className: 'mb-3'
                                                                                }}
                                                                                onChange={e =>
                                                                                    setFieldValue(
                                                                                        field.name,
                                                                                        e.target.checked
                                                                                    )
                                                                                }
                                                                                checked={field.value}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                    <FormError error={errors.is_company}/>
                                                                </Form.Group>
                                                                {values?.is_company && (
                                                                    <Form.Group>
                                                                        <Field name="company">
                                                                            {({field}) => (
                                                                                <Select
                                                                                    options={companyOptions}
                                                                                    onMenuScrollToBottom={e => {
                                                                                        console.log(e);
                                                                                        setCompanyPage(page => page + 1);
                                                                                    }}
                                                                                    onInputChange={e => {
                                                                                        query.set('search', e);
                                                                                        fetchCompanies();
                                                                                    }}
                                                                                    placeholder={`${t('select', {ns: "common"})} ${t('fields.company')}`}
                                                                                    classNamePrefix="react-select"
                                                                                    value={selectedCompany}
                                                                                    onChange={value => {
                                                                                        setFieldValue(
                                                                                            field.name,
                                                                                            value.value
                                                                                        );
                                                                                        setSelectedCompany(value);
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        </Field>
                                                                        <FormError error={errors.company}/>
                                                                    </Form.Group>
                                                                )}
                                                            </>
                                                        )}
                                                    </Card.Body>
                                                </Card>
                                                {(group?.indexOf(
                                                    process.env.REACT_APP_ADMIN_GROUP_NAME
                                                ) !== -1 || group?.indexOf(
                                                    process.env.REACT_APP_ACTIVITY_GROUP_NAME
                                                ) !== -1) && (
                                                    <Card className={'mt-3'}>
                                                        <CardHeader>
                                                            <h5 className="mb-0 text-muted">
                                                                {t('fields.groups_permissions')} <span
                                                                className={"text-warning"}>*</span>
                                                            </h5>
                                                        </CardHeader>
                                                        <Card.Body>
                                                            <Form.Group>
                                                                <Form.Label>{t('fields.groups')}</Form.Label>
                                                                <Field name={'groups'}>
                                                                    {({field, form}) => (
                                                                        <Select
                                                                            options={groupOptions}
                                                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.groups')}`}
                                                                            isMulti
                                                                            classNamePrefix="react-select"
                                                                            value={selectedGroups}
                                                                            onChange={selectedOptions => {
                                                                                const values = selectedOptions.map(
                                                                                    option => option.value
                                                                                );
                                                                                form.setFieldValue(field.name, values);
                                                                                setSelectedGroups(selectedOptions);
                                                                            }}
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <FormError error={errors.groups}/>
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Label>{t("fields.permissions")}</Form.Label>
                                                                <Field name={'user_permissions'}>
                                                                    {({field, form}) => (
                                                                        <Select
                                                                            placeholder={`${t('select', {ns: "common"})} ${t('fields.permissions')}`}
                                                                            inputValue={selectInput}
                                                                            onInputChange={onInputChange}
                                                                            filterOption={customFilterOption}
                                                                            components={{
                                                                                Option: Option,
                                                                                Input: Input,
                                                                            }}
                                                                            styles={customStyles}
                                                                            closeMenuOnSelect={false}
                                                                            tabSelectsValue={false}
                                                                            backspaceRemovesValue={false}
                                                                            hideSelectedOptions={false}
                                                                            blurInputOnSelect={false}
                                                                            options={[allOption, ...permissionOptions]}
                                                                            value={selectedPermissions}
                                                                            onChange={(newValue) => {
                                                                                handleChange(newValue)
                                                                                const values = newValue.map(
                                                                                    option => option.value
                                                                                );
                                                                                form.setFieldValue(field.name, values);
                                                                            }}
                                                                            onKeyDown={onKeyDown}
                                                                            onMenuScrollToBottom={() => {
                                                                                setPermissionsPage(prevState => prevState + 1)
                                                                            }}
                                                                            classNamePrefix={"react-select"}
                                                                            isMulti
                                                                        />
                                                                    )}
                                                                </Field>
                                                                <FormError error={errors.user_permissions}/>
                                                            </Form.Group>
                                                        </Card.Body>
                                                    </Card>
                                                )}
                                                <Card className={'mt-3'}>
                                                    <CardHeader>
                                                        <h5 className="mb-0 text-muted">{t("fields.password")}</h5>
                                                    </CardHeader>
                                                    <Card.Body>
                                                        <Form.Group>
                                                            <Form.Label>{t("fields.password")}</Form.Label>
                                                            <Field name={'password'}>
                                                                {({field}) => (
                                                                    <Form.Control
                                                                        type="password"
                                                                        label="Password"
                                                                        name="password"
                                                                        placeholder={t("fields.password")}
                                                                        errors={errors}
                                                                        formGroupProps={{
                                                                            className: 'mb-3'
                                                                        }}
                                                                        formControlProps={{
                                                                            className: 'input-spin-none'
                                                                        }}
                                                                        onChange={e =>
                                                                            setFieldValue(field.name, e.target.value)
                                                                        }
                                                                        value={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors.password}/>
                                                        </Form.Group>
                                                    </Card.Body>
                                                </Card>
                                                <Card className="mt-3">
                                                    <CardHeader>
                                                        <h5 className="mb-0 text-muted">
                                                            Account Settings
                                                        </h5>
                                                    </CardHeader>
                                                    <Card.Body className="bg-light">
                                                        <div>
                                                            <h6 className="fw-bold">
                                                                Who can see your profile ?
                                                                <TooltipBadge
                                                                    tooltip="Only The group of selected people can see your profile"
                                                                    icon="question-circle"
                                                                />
                                                            </h6>
                                                            <div className="ps-2 mb-2">
                                                                <Field name={"account_settings.visibility"}>
                                                                    {({field}) => (
                                                                        <>
                                                                            <Form.Check
                                                                                type="radio"
                                                                                id="profile-everyone"
                                                                                label="Everyone"
                                                                                className="form-label-nogutter"
                                                                                value="everyone"
                                                                                name="visibility"
                                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                                checked={field.value === 'everyone'}
                                                                            />
                                                                            <Form.Check
                                                                                type="radio"
                                                                                id="profile-followers"
                                                                                label="My Members"
                                                                                className="form-label-nogutter"
                                                                                value="followers"
                                                                                name="visibility"
                                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                                checked={field.value === 'followers'}
                                                                            />
                                                                            <Form.Check
                                                                                type="radio"
                                                                                id="profile-members"
                                                                                label="Only Me"
                                                                                className="form-label-nogutter"
                                                                                value="me"
                                                                                name="visibility"
                                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                                checked={field.value === 'me'}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                            <FormError error={errors?.account_settings?.visibility}/>
                                                        </div>

                                                        <div>
                                                            <h6 className="fw-bold">
                                                                Who can tag you ?
                                                                <TooltipBadge
                                                                    tooltip="Only The group of selected people can tag your"
                                                                    icon="question-circle"
                                                                />
                                                            </h6>
                                                            <div className="ps-2">
                                                                <Field name={"account_settings.tag"}>
                                                                    {({field}) => (
                                                                        <>
                                                                            <Form.Check
                                                                                type="radio"
                                                                                id="tag-everyone"
                                                                                label="Everyone"
                                                                                className="form-label-nogutter"
                                                                                value="everyone"
                                                                                name="tag"
                                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                                checked={field.value === 'everyone'}
                                                                            />
                                                                            <Form.Check
                                                                                type="radio"
                                                                                id="tag-members"
                                                                                label="Group Members"
                                                                                className="form-label-nogutter"
                                                                                value="members"
                                                                                name="tag"
                                                                                onChange={(e) => setFieldValue(field.name, e.target.value)}
                                                                                checked={field.value === 'members'}
                                                                            />
                                                                        </>
                                                                    )}
                                                                </Field>

                                                            </div>
                                                            <FormError error={errors?.account_settings?.tag}/>
                                                        </div>

                                                        <div className="border-dashed border-bottom my-3"/>

                                                        <div className="ps-2">
                                                            <Field name={"account_settings.show_followers"}>
                                                                {({field}) => (
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        id="show-followers"
                                                                        label="Allow users to show your followers"
                                                                        className="form-label-nogutter"
                                                                        name="show_followers"
                                                                        onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError
                                                                error={errors?.account_settings?.show_followers}/>
                                                            <Field name={"account_settings.show_emails"}>
                                                                {({field}) => (
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        id="show-email"
                                                                        label="Allow users to show your emails"
                                                                        className="form-label-nogutter"
                                                                        name="show_emails"
                                                                        onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors?.account_settings?.show_emails}/>
                                                            <Field name={"account_settings.show_experience"}>
                                                                {({field}) => (
                                                                    <Form.Check
                                                                        type="checkbox"
                                                                        id="show-experience"
                                                                        label="Allow users to show your experiences"
                                                                        className="form-label-nogutter"
                                                                        name="show_experience"
                                                                        onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError
                                                                error={errors?.account_settings?.show_experience}/>
                                                        </div>

                                                        <div className="border-dashed border-bottom my-3"/>

                                                        <div className="ps-2">
                                                            <Field name={"account_settings.phone_visible"}>
                                                                {({field}) => (
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        label="Make your phone number visible"
                                                                        className="form-label-nogutter"
                                                                        name="phone_visible"
                                                                        onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors?.account_settings?.phone_visible}/>
                                                            <Field name={"account_settings.user_follow"}>
                                                                {({field}) => (
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        label="Allow user to follow you"
                                                                        className="form-label-nogutter"
                                                                        name="user_follow"
                                                                        onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError error={errors?.account_settings?.user_follow}/>
                                                            <Field name={"account_settings.newsletter_subscribed"}>
                                                                {({field}) => (
                                                                    <Form.Check
                                                                        type="switch"
                                                                        id="custom-switch"
                                                                        label="Receive our newsletter updates"
                                                                        className="form-label-nogutter"
                                                                        name="newsletter_subscribed"
                                                                        onChange={(e) => setFieldValue(field.name, e.target.checked)}
                                                                        checked={field.value}
                                                                    />
                                                                )}
                                                            </Field>
                                                            <FormError
                                                                error={errors?.account_settings?.newsletter_subscribed}/>
                                                        </div>
                                                    </Card.Body>
                                                </Card>
                                            </div>
                                        </Col>
                                    </Row>
                                </Form>

                                <Card>
                                    <CardHeader>
                                        <Flex justifyContent={'between'} alignItems={"center"} wrap={'wrap'}>
											<span>
												<ul>
													<li className={"text-decoration-none text-danger"}>* {t('mandatory', {ns: "common"})}</li>
													<li className={"text-decoration-none text-warning"}>* {t('recommended', {ns: "common"})}</li>
												</ul>
											</span>
                                            <IconButton
                                                icon={faSave}
                                                onClick={e => handleSubmit(e, values)}
                                            >
                        <span className="d-none d-sm-inline-block ms-1">
                          {t("fields.save")}
                        </span>
                                            </IconButton>
                                        </Flex>
                                    </CardHeader>
                                </Card>
                            </>
                        )}
                    </Formik>
                </>
            )}
        </Fragment>
    );
};

export default withPermission(
    withTranslation(["users", "common"])(NewEditUser),
    "accounts.add_user"
);
